import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(reactI18nextModule) // if not using I18nextProvider
  .init({
    //lng: 'en', // Overrides language detection

    fallbackLng: 'en',
    debug: true,
    preload: ['en', 'es', 'jp', 'pt'], // preload the languagues

    // have a common namespace used around the full app [default is translation]
    // ns: ['translation'],
    // defaultNS: 'translation',

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // react i18next special options (optional)
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  })

export default i18n
