import React, { Component } from 'react'

import Wrapper from '../components/common/wrapper'
import StyledContainer from '../components/common/container'
import StyledContent from '../components/common/content'

import styled from 'styled-components'

//shift top down by the height of the mobile/desktop header
const Anchor = styled.a`
  display: block;
  position: relative;
  top: -75px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    top: -115px;
  }
`

class Container extends Component {
  render() {
    const content = this.props.children || this.props.content
    const { containerId, background, anchorRef, containerStyle } = this.props

    return (
      <div>
        <Anchor id={containerId} innerRef={anchorRef} />
        <Wrapper id="Internet Explorer Wrapper">
          <StyledContainer id="Container" background={background} style={containerStyle}>
            <StyledContent id="Content">{content}</StyledContent>
          </StyledContainer>
        </Wrapper>
      </div>
    )
  }
}

export default Container
