import styled from 'styled-components'

export const LeftColumn = styled.div`
  height: auto;
  width: 100%;

  transition-property: width, margin;
  transition-duration: 0.5s;

  padding: 47px 0px 50px 0px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: 515px;
    position: relative;
    float: left;

    margin: 0px 0px 0px 0px;
    padding: 47px 100px 50px 0px;
  }
`

export const LeftColumnWider = styled.div`
  height: auto;
  width: 100%;

  transition-property: width, margin;
  transition-duration: 0.5s;

  padding: 47px 0px 50px 0px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: 515px;
    position: relative;
    float: left;

    margin: 0px 0px 0px 0px;
    padding: 47px 75px 50px 0px;
  }
`