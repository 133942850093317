import styled from 'styled-components'

export default styled.div`
  color: #002244;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;

  & > a {
    color: #006080;
  }
`
