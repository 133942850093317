import kountSDK from '@kount/kount-web-client-sdk'

export const fraudDetection = async function(sessionID) {
  let kountConfig = {
      clientID: process.env.REACT_APP_KOUNT_CLIENT_ID,
      environment: process.env.REACT_APP_KOUNT_ENV,
      isSinglePageApp: true,
  }
  kountSDK(kountConfig, sessionID)
}
