import styled from 'styled-components'

export default styled.div`
  background: #c9d1e8;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  padding: 35px 45px;
  margin-bottom: 35px;

  transition-property: width, margin;
  transition-duration: 0.5s;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: 385px;
    margin: 0px 0px 0px 0px;
  }
`
