import styled from 'styled-components'

export default styled.button`
  background-color: #6244bb;
  color: white;
  width: 100%;
  padding: 20px 20px;
  margin: 25px 0 0 0;
  border: solid;
  border-width: 2px;
  border-color: #755bc4;
  border-radius: 5px;
  font-size: 16px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: 300px;
  }
`
