import React, { Component } from 'react'
import styled from 'styled-components'
import { translate } from 'react-i18next'

import Container from '../../containers/Container'
import ButtonLink from '../common/buttonLink'

import imgSrc from '../../images/enrollLinkLogo2024.png'

const StyledEnrollLink = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    flex-direction: row;
  }
`

const Image = styled.img`
  height: 82px;
  width: 254px;
  margin: 0px auto 30px auto;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    margin: 75px 50px 0px 75px;
  }
`

const Content = styled.div`
  width: 100%;
  padding: 10px 30px;
  border: none;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: 500px;
    border-left: solid;
    border-width: 1px;
    border-color: #ebebeb;
  }
`

const StyledP = styled.p`
  font-size: 17px;
  margin-bottom: 28px;
  white-space: pre-line;
`

export class EnrollLink extends Component {
  render() {
    const { t } = this.props

    return (
      <Container style={{}}>
        <StyledEnrollLink>
          <Image src={imgSrc} alt="United" />
          <Content>
            <StyledP>{t('enrollLink.text')}</StyledP>
            <ButtonLink target={t('enrollLink.buttonUri')} text={t('enrollLink.buttonText')} />
          </Content>
        </StyledEnrollLink>
      </Container>
    )
  }
}

export default translate('translation')(EnrollLink)
