import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import FormContainer from '../../common/formContainer'
import Subtitle from '../../common/formSubTitle'
import Title from '../../common/formTitle'
import StyledText from '../../common/formText'

const StyledSpan = styled.span`
  font-weight: 600;
`

const Anchor = styled.a`
  color: #002244;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`

class RightColumnSuccess extends Component {
  render() {
    const t = this.props.t

    const firstName = this.props.profile.firstname
    const memberLevel = this.props.profile.memberLevel
    const eliteLevels = this.props.elite
    const maskedMP = this.props.maskedMP
    const maskedMR = this.props.maskedMR
    const transferBalance = this.props.transferMiles.data.balance

    return (
      <FormContainer id="RightColumn">
        <div>
          <Title>
            {t('miles.right.marriott.title')} {firstName}
          </Title>
          <Subtitle>
            <StyledSpan>
              <Trans i18nKey={eliteLevels[memberLevel]}>
                Premier
                <sup>®</sup> 1K
                <sup>®</sup>
              </Trans>
            </StyledSpan>
            <br />
            <StyledSpan>
              <Trans i18nKey="miles.right.marriott.mp_number_title">
                MileagePlus
                <sup>®</sup> number:
              </Trans>
            </StyledSpan>
            &nbsp;
            {maskedMP}
            <br />
            <StyledSpan>{t('miles.right.marriott.balance')}</StyledSpan> {transferBalance}
            <br />
            <StyledSpan>{t('miles.right.convert.mr_number_title')}</StyledSpan> {maskedMR}
          </Subtitle>

          <StyledText>{t('miles.right.success.textA')}</StyledText>
          
          <StyledText>
            <Trans i18nKey="miles.right.success.textC">
              A <Anchor href="home">Back to preferred partnership home page</Anchor>
            </Trans>
          </StyledText>
        </div>
      </FormContainer>
    )
  }
}

export default translate('translation')(RightColumnSuccess)
