import React, { Component } from 'react'
import PropTypes from 'prop-types'

const isModifiedEvent = event => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

class CustomLink extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  handleClick = event => {
    if (this.props.onClick) {
      this.props.onClick(event)
    }

    if (
      event.button === 0 && // ignore everything but left clicks
      !this.props.target && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault()

      const { history } = this.context.router
      const { replace, to } = this.props

      if (replace) {
        history.replace(to)
      } else {
        history.push(to)
      }

      //scroll section into view
      const trimmedUrlHash = to.substring(2)
      const section = document.getElementById(trimmedUrlHash)
      console.log('CustomLink: trimmedUrlHash = ' + trimmedUrlHash + ', section = ' + section)
      if (section) {
        section.scrollIntoView()
      }
    }
  }

  render() {
    const { Component, ...props } = this.props
    return <Component {...props} onClick={this.handleClick} />
  }
}

export default CustomLink
