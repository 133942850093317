import styled from 'styled-components'

// https://thatemil.com/blog/2013/11/03/sticky-footers-flexbox-and-ie10/

export default styled.div`
  width: 100%;
  position: relative;

  flex: 1 0 auto;
  flex-direction: column;
  align-self: center;

  padding: 0px 5%;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: ${props => props.theme.desktop.contentWidth};
    flex-direction: row;
    padding: 0;
  }
`
