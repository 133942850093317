import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Trans, translate } from 'react-i18next'

import { marriottNumber } from '../../../../actions'

import renderField from '../../../form/renderField'
import { isNumeric, required } from '../../../form/validators'
import Button from '../../../common/button'
import FormText from '../../../common/formText'

class MarriottForm extends Component {
  renderForm() {
    const t = this.props.t
    const { handleSubmit, submitting } = this.props

    const validateMRAccount = [required, isNumeric]

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="mr_number"
          label={t('signup.right.marriott.form.mr_number')}
          formtype="Signup"
          component={renderField}
          type="text"
          validate={validateMRAccount}
        >
          <FormText>
            <Trans i18nKey="signup.right.marriott.form.forgot_mr">
              A
              <a href={t('signup.uri.right.forgot_mr')} target="_blank" rel="noopener noreferrer">
                Forgot your Marriott Bonvoy number?
              </a>
            </Trans>
          </FormText>
          <FormText>
            <Trans i18nKey="signup.right.marriott.form.create_mr">
              Not a Marriott Bonvoy member?
              <a
                href={t('signup.uri.right.create_mr')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                Join now
              </a>
            </Trans>
          </FormText>
        </Field>

        <Button type="submit" disabled={submitting} alt="Sign Up">
          {t('signup.right.marriott.form.button')}
        </Button>
      </form>
    )
  }

  render() {
    return this.renderForm()
  }
}

// Decorate the form component with redux-form
const MarriottReduxForm = reduxForm({
  form: 'marriottNumberForm', // a unique name for this form
})(MarriottForm)

// Decorate the form component with connect
const mapStateToProps = (state, ownProps) => {
  return {
    //...state,
  } // when in doubt pass nothing
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (formData, dispatch, props) => {
      dispatch(props.startSubmit)
      dispatch(marriottNumber(formData))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translation')(MarriottReduxForm))
