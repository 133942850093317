import React, { Component } from 'react'
import styled from 'styled-components'
import i18next from 'i18next'

const StyledContent = styled.div`
  background: #002244;
  color: #ffffff;
  font-family: ${props => (/^jp/.test(i18next.language) ? props.theme.font.jp : props.theme.font.default)};
  font-size: 64px;
  opacity: 0.9;

  width: 100%;
  min-height: 400px;

  transition-property: width, margin;
  transition-duration: 0.5s;

  margin: 30px 0;
  padding: 45px;
`

const StyledErrorHeader = styled.div``

const StyledErrorNumber = styled.div`
  color: red;
  font-weight: 900;
`

const StyledErrorMessage = styled.div`
  font-size: 48px;
`

class ErrorPage extends Component {
  render() {
    const error = this.props.error
    return (
      <StyledContent>
        <StyledErrorHeader>Error: </StyledErrorHeader>
        <StyledErrorNumber>{error ? error.status : '404'}</StyledErrorNumber>
        <StyledErrorMessage>
          {error ? (error.data ? error.data.message : error.error.message) : 'The page you are looking for does not exist.'}
        </StyledErrorMessage>
      </StyledContent>
    )
  }
}

export default ErrorPage
