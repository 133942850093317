import React from 'react'
import { FadeLoader } from 'react-spinners'

class Loader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }
  render() {
    return (
      <div id="Loader">
        <FadeLoader
          loaderStyle={{ display: 'block', margin: '100px auto', borderColor: 'red' }}
          sizeUnit={'px'}
          size={'150'}
          color={'#002244'}
          loading={this.state.loading}
        />
      </div>
    )
  }
}

export default Loader
