import React, { Component } from 'react'
import { translate } from 'react-i18next'

import { RightColumn as RightColumnTemplate } from '../common/rightColumn'
import ButtonLink from '../common/buttonLink.jsx'


class RightColumn extends Component {
  render() {
    const { t } = this.props

    return (
      <RightColumnTemplate id="RightColumn">
        <ButtonLink target={t('points.right.buttonUri')} text={t('points.right.buttonText')} openInNew={true} />
      </RightColumnTemplate>
    )
  }
}

export default translate('translation')(RightColumn)
