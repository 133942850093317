import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import { overrideMarriottNumber } from '../../../actions'

import ConvertForm from './forms/convertForm'

import FormContainer from '../../common/formContainer'
import Subtitle from '../../common/formSubTitle'
import Title from '../../common/formTitle'
import FormText from '../../common/formText'

const Section = styled.div`
  margin-bottom: 15px;
`

const StyledSpan = styled.span`
  font-weight: 600;
`

const StyledLink = styled.span`
  color: #006080;
  cursor: pointer;

  font-size: 12px;
  margin-top: -20px;

  &:hover,
  :focus {
    text-decoration: underline;
  }
`

export class RightColumnConvert extends Component {
  static contextTypes = { store: PropTypes.object }
  handleClick = event => {
    const { marriott } = this.props
    const {
      store: { dispatch },
    } = this.context
    dispatch(overrideMarriottNumber(marriott.marriottNumber))
  }
  render() {
    const t = this.props.t

    const firstName = this.props.profile.firstname
    const balance = this.props.profile.balance
    const memberLevel = this.props.profile.memberLevel
    const eliteLevels = this.props.elite
    const maskedMP = this.props.maskedMP
    const maskedMR = this.props.maskedMR

    return (
      <FormContainer id="RightColumn">
        <div>
          <Title>
            {t('miles.right.marriott.title')} {firstName}
          </Title>
          <Section>
            <Subtitle>
              <StyledSpan>
                <Trans i18nKey={eliteLevels[memberLevel]}>
                  Premier
                  <sup>®</sup> 1K
                  <sup>®</sup>
                </Trans>
              </StyledSpan>
              <br />
              <StyledSpan>
                <Trans i18nKey="miles.right.marriott.mp_number_title">
                  MileagePlus
                  <sup>®</sup> number:
                </Trans>
              </StyledSpan>
              &nbsp;
              {maskedMP}
              <br />
              <StyledSpan>{t('miles.right.marriott.balance')}</StyledSpan> {balance}
            </Subtitle>
            <FormText>
              <Trans i18nKey="miles.right.convert.textA">
                Congratulations — as a MileagePlus Premier
                <sup>®</sup> member you're eligible to convert award miles into Marriott Bonvoy points.
              </Trans>
            </FormText>
          </Section>
          <Section>
            <Subtitle>
              <StyledSpan>{t('miles.right.convert.mr_number_title')}</StyledSpan> {maskedMR}
              <br />
              <StyledLink id="notmr" onClick={this.handleClick}>
                {t('miles.right.convert.not_mr')}
              </StyledLink>
            </Subtitle>
            <FormText>{t('miles.right.convert.textB')}</FormText>
          </Section>

          {/* FORM */}
          <ConvertForm balance={balance} />
        </div>
      </FormContainer>
    )
  }
}

export default translate('translation')(RightColumnConvert)
