import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import { LeftColumn as LeftColumnTemplate } from '../common/leftColumn'
import { Title } from '../common/title'
import { Paragraph } from '../common/paragraph'

const StyledLeftColumn = styled(LeftColumnTemplate)`
  //
`

const StyledName = styled.span`
  white-space: nowrap;
`

class LeftColumn extends Component {
  render() {
    const t = this.props.t

    return (
      <StyledLeftColumn id="LeftColumn">
        <Title>
          <Trans i18nKey="points.left.title">
            ..
            <StyledName>..</StyledName>
            ..
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
          </Trans>
        </Title>
        <Paragraph>
          <Trans i18nKey="points.left.text">
            ..
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            ..
          </Trans>
        </Paragraph>
      </StyledLeftColumn>
    )
  }
}

export default translate('translation')(LeftColumn)
