import React from 'react'
import styled from 'styled-components'
import OpenInNew from '@material-ui/icons/OpenInNew'

const A = styled.a`
  width: 100%;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#6244bb')};
  color: ${props => (props.color ? props.color : 'white')};
  width: ${props => (props.width ? props.width : '100%')}
  padding: ${props => (props.padding ? props.padding : '20px 20px')}
  border: solid;
  border-width: 2px;
  border-color: ${props => (props.borderColor ? props.borderColor : '#755bc4')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '5px')};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: ${props => (props.width ? props.width : '285px')};
  }
`

export class ButtonLink extends React.Component {
  render() {
    const { target, text, width, padding, backgroundColor, color, borderColor, borderRadius,fontSize, fontWeight, openInNew, targetBlank } = this.props
    return (
      <A href={target} target={targetBlank ? '_blank' : ''}>
        <Button width={width} padding={padding} backgroundColor={backgroundColor} color={color} borderColor={borderColor} borderRadius={borderRadius} fontSize={fontSize} fontWeight={fontWeight}>
          {text} 
          {openInNew && <span>&nbsp;</span>}
          {openInNew && <OpenInNew />}
        </Button>
      </A>
    )
  }
}

export default ButtonLink
