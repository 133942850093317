import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

const StyledHelmet = styled.div`
  display: none;
`

class HelmetHead extends React.Component {
  render() {
    return (
      <StyledHelmet id="StyledHelmet">
        <Helmet 
          script={[{ 
            type: 'text/javascript', 
            innerHTML: "(function(a,b,c,d){a='"+process.env.REACT_APP_TEALIUM_SRC+"';b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);})();" 
          }]} 
        />
      </StyledHelmet>
    )
  }
}

export default HelmetHead
