import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import './header.css'

import CustomLink from './customLink'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import Nav from 'react-bootstrap/lib/Nav'
import Navbar from 'react-bootstrap/lib/Navbar'
import NavDropdown from 'react-bootstrap/lib/NavDropdown'
import UnstyledNavItem from 'react-bootstrap/lib/NavItem'

import headerLogo from '../../images/headerLogos2024.png'
import { logout } from '../../actions'
import {connect} from 'react-redux';

const languageCodeMap = {
  en: 'English',
  'en-US': 'English',
  es: 'Español',
  pt: 'Português',
  jp: '日本語',
}

const eliteLevels = [
  'miles.elite_status.general',
  'miles.elite_status.silver',
  'miles.elite_status.gold',
  'miles.elite_status.platinum',
  'miles.elite_status.onek',
  'miles.elite_status.global',
]

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  margin: 0px 0 0 0;
  background-color: #0c2340;
  background-image: none;
  color: white;
  z-index: 9999;
  padding: 0 5%;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 0;
  }
`

const LogoBar = styled.div`
  height: 75px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: ${props => props.theme.desktop.contentWidth};
    margin: auto;
  }
`

const NavItem = styled(({ currentSection, ...other }) => <UnstyledNavItem {...other} />)`
  text-decoration: ${props => (props.to === '/' + props.currentSection ? 'underline' : 'none')};
`

// More styling in header.css

class Header extends Component {
  render() {
    const i18n = this.props.i18n
    const t = this.props.t
    const currentSection = this.props.currentSection

    //i18next handles the fallback from say es-ES to es
    //this will give us the right option in the language selector
    //falls back to en for languages not in the language code map (same behaviour as the translations)
    const currentLanguage = i18n.language ? i18n.language.substring(0, 2) : 'en'
    const currentLanguageCode = languageCodeMap[currentLanguage] || languageCodeMap['en']

    return (
      <HeaderContainer id="HeaderContainer">
        <LogoBar>
          <img
            src={headerLogo}
            alt={'Preferred Partnership Logo'}
            useMap="#logoMap"
            style={{  width: '300px', marginTop: '5px' }}
          />
          <map name="logoMap">
            <area shape="rect" coords="0,0,150,60" alt="United" href="https://united.com" />
            <area shape="rect" coords="165,0,348,60" alt="Marriott" href="https://fly-united.marriott.com/en_US/" />
          </map>
        </LogoBar>
        <Navbar collapseOnSelect>
          <Navbar.Header>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <CustomLink Component={NavItem} eventKey={2} to="/#signup" currentSection={currentSection}>
                {t('header.breadcrumb.navigation.signup')}
              </CustomLink>
              <CustomLink Component={NavItem} eventKey={3} to="/#points" currentSection={currentSection}>
                {t('header.breadcrumb.navigation.points')}
              </CustomLink>
              <CustomLink Component={NavItem} eventKey={5} to="/#marriottLogin" currentSection={currentSection}>
                {t('header.breadcrumb.navigation.marriottLogin')}
              </CustomLink>
              <CustomLink Component={NavItem} eventKey={6} to="/#miles" currentSection={currentSection} style={{ border: 'none' }}>
                {t('header.breadcrumb.navigation.miles')}
              </CustomLink>
              {!this.props.profile && (
                <NavItem id="enrollLink" href={t('header.signedinBar.uri.joinMileagePlus')}>
                  {t('header.signedinBar.text.joinMileagePlus')}
                </NavItem>
              )}
              {this.props.profile &&
                this.props.profile.memberLevel !== undefined && (
                  <NavItem id="memberLevel" href="">
                    <p>
                      {t('header.signedinBar.text.status')}:
                      <Trans i18nKey={eliteLevels[this.props.profile.memberLevel]}>
                        ..
                        <sup>..</sup> ..
                        <sup>..</sup>
                      </Trans>
                    </p>
                  </NavItem>
                )}
              {this.props.profile &&
                this.props.profile.balance !== undefined && (
                  <NavItem id="balance" href="">
                    <p>
                      {t('header.signedinBar.text.mileageBalance')}: {this.props.profile.balance}
                    </p>
                  </NavItem>
                )}
              
              <NavDropdown id="languageDropdown" eventKey={3} title={currentLanguageCode}>
                <MenuItem name="en" eventKey={1.1} onClick={() => i18n.changeLanguage('en')}>
                  {languageCodeMap['en']}
                </MenuItem>
                <MenuItem name="es" eventKey={1.2} onClick={() => i18n.changeLanguage('es')}>
                  {languageCodeMap['es']}
                </MenuItem>
                <MenuItem name="pt" eventKey={1.3} onClick={() => i18n.changeLanguage('pt')}>
                  {languageCodeMap['pt']}
                </MenuItem>
                <MenuItem name="jp" eventKey={1.4} onClick={() => i18n.changeLanguage('jp')}>
                  {languageCodeMap['jp']}
                </MenuItem>
              </NavDropdown>

              {this.props.profile && (
                <NavItem id="logOut" onSelect={this.props.logout}>
                {t('header.signoutText')}
                </NavItem>
                )}
              
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </HeaderContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    authCode: state.Store.authCode,
    ...state,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translation')(Header))
