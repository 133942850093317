import React, { Component } from 'react'
import { translate } from 'react-i18next'

import Loader from '../../loader/loader'

import FormContainer from '../../common/formContainer'
import Subtitle from '../../common/formSubTitle'
import Title from '../../common/formTitle'

class RightColumnLoader extends Component {
  render() {
    const t = this.props.t

    return (
      <FormContainer id="RightColumn">
        <div>
          <Title>{t('miles.right.signin.title')}</Title>
          <Subtitle>{t('miles.right.signin.subtitle')}</Subtitle>

          {/* FORM */}
          <Loader />
        </div>
      </FormContainer>
    )
  }
}

export default translate('translation')(RightColumnLoader)
