import styled from 'styled-components'

export const RightColumn = styled.div`
  background: #c9d1e8;

  width: calc(100% + 100px);
  margin: 0 -50px;
  padding: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition-property: width, margin;
  transition-duration: 0.5s;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: 385px;
    float: right;
    margin: 0px 0px 0px 0px;
  }
`
