import React, { Component } from 'react'
import styled from 'styled-components'
import { Redirect } from 'react-router'

import LeftColumn from '../components/miles/leftColumn'
import RightColumnSignin from '../components/miles/right_column/rightColumnSignin'
import RightColumnLoader from '../components/miles/right_column/rightColumnLoader'
import RightColumnMarriott from '../components/miles/right_column/rightColumnMarriott'
import RightColumnConvert from '../components/miles/right_column/rightColumnConvert'
import RightColumnConfirm from '../components/miles/right_column/rightColumnConfirm'
import RightColumnMessages from '../components/miles/right_column/rightColumnMessages'
import RightColumnSuccess from '../components/miles/right_column/rightColumnSuccess'
import { maskAccount } from '../actions'
import { trackEvent } from '../analytics/reactGAMiddlewares'
import { fraudDetection } from '../fraud/kount'

const StyledSignup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 0px 0px 0px 0px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    flex-direction: row;
  }
`

const elite_levels = [
  'miles.elite_status.general',
  'miles.elite_status.silver',
  'miles.elite_status.gold',
  'miles.elite_status.platinum',
  'miles.elite_status.onek',
  'miles.elite_status.global',
]

class Miles extends Component {
  render() {
    const authCode = this.props.authCode.onlineAuthCode
    const profile = this.props.profile.data
    const marriott = this.props.marriott
    const convertMiles = this.props.convertMiles
    const transferMiles = this.props.transferMiles

    // Event Tracking:
    const category = 'Miles:'

    if (this.props.profile.error || (transferMiles.error && transferMiles.error.status >= 500 && !transferMiles.data.exception)) {
      const error = this.props.profile.error ? this.props.profile.error : transferMiles.error
      const event = {
        category: category,
        action: 'Profile | Transfer Network Error',
        label: `ErrorPage: ${this.props.profile.error ? 'Profile Network Error' : 'Transfer Network Error'}`,
        value: 0,
      }
      trackEvent(event)
      return <Redirect to={{ pathname: '/error', state: { error: error } }} />
    }
    if (profile) {
      const maskedMP = maskAccount(profile.account)

      //if (profile.memberLevel > 1) { //bypass
      if (profile.memberLevel < 1) {
        const event = {
          category: category,
          action: 'profile.memberLevel < 1',
          label: 'ErrorA: Member not eligible',
          value: 0,
        }
        trackEvent(event)
        return (
          <StyledSignup>
            <LeftColumn />
            <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorA" maskedMP={maskedMP} />
          </StyledSignup>
        )
      } else if (profile.isBlacklisted) {
        const event = {
          category: category,
          action: 'profile.isBlacklisted',
          label: 'ErrorH: Member not eligible see T&C',
          value: 0,
        }
        trackEvent(event)
        return (
          <StyledSignup>
            <LeftColumn />
            <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorH" maskedMP={maskedMP} />
          </StyledSignup>
        )
      }
      if (!marriott.overrideMarriottNumber && (marriott.marriottNumber || profile.marriottNumber)) {
        let maskedMR = null
        let mr = null
        if (marriott.marriottNumber) {
          maskedMR = maskAccount(marriott.marriottNumber)
          mr = marriott
        } else {
          maskedMR = maskAccount(profile.marriottNumber)
          mr = { marriottNumber: profile.marriottNumber }
        }
        if (transferMiles.transferring) {
          if (transferMiles.data.confirmationNumber) {
            const event = {
              category: category,
              action: `transferMiles.data.confirmationNumber: success`,
              label: 'Success',
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnSuccess
                  profile={profile}
                  marriott={mr}
                  elite={elite_levels}
                  maskedMP={maskedMP}
                  maskedMR={maskedMR}
                  transferMiles={transferMiles}
                />
              </StyledSignup>
            )
          } else if (transferMiles.data.exception.exceptionCode === '131') {
            const event = {
              category: category,
              action: `transferMiles.data.exception.exceptionCode: ${transferMiles.data.exception.exceptionCode}`,
              label: 'ErrorG: Error processing transaction',
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorG" maskedMP={maskedMP} />
              </StyledSignup>
            )
          } else if (transferMiles.data.exception.exceptionCode === '203') {
            const event = {
              category: category,
              action: `transferMiles.data.exception.exceptionCode: ${transferMiles.data.exception.exceptionCode}`,
              label: 'ErrorL: Error Security Fraud',
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorL" maskedMP={maskedMP} />
              </StyledSignup>
            )
          } else if (transferMiles.data.exception.exceptionCode === '204') {
            const event = {
              category: category,
              action: `transferMiles.data.exception.exceptionCode: ${transferMiles.data.exception.exceptionCode}`,
              label: 'ErrorJ: There was a system error while verifying your credentials',
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorJ" maskedMP={maskedMP} />
              </StyledSignup>
            )
          } else if (transferMiles.data.exception.exceptionCode === '210') {
            const event = {
              category: category,
              action: `transferMiles.data.exception.exceptionCode: ${transferMiles.data.exception.exceptionCode}`,
              label: 'ErrorH: Member not eligible see T&C',
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorH" maskedMP={maskedMP} />
              </StyledSignup>
            )
          } else if (transferMiles.data.exception.exceptionCode === '214') {
            const event = {
              category: category,
              action: `transferMiles.data.exception.exceptionCode: ${transferMiles.data.exception.exceptionCode}`,
              label: 'ErrorJ: System error',
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorJ" maskedMP={maskedMP} />
              </StyledSignup>
            )
          } else if (transferMiles.data.exception.exceptionCode === '212') {
            const event = {
              category: category,
              action: `transferMiles.data.exception.exceptionCode: ${transferMiles.data.exception.exceptionCode}`,
              label: 'ErrorA: Member not eligible',
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorA" maskedMP={maskedMP} />
              </StyledSignup>
            )
          } else if (transferMiles.data.exception.exceptionCode === '213') {
            const event = {
              category: category,
              action: `transferMiles.data.exception.exceptionCode: ${transferMiles.data.exception.exceptionCode}`,
              label: 'ErrorD: Marriott account does not match last name from United MileagePlus member profile',
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorD" maskedMP={maskedMP} />
              </StyledSignup>
            )
          } else if (transferMiles.data.exception.exceptionCode === '216') {
            const event = {
              category: category,
              action: `transferMiles.data.exception.exceptionCode: ${transferMiles.data.exception.exceptionCode}`,
              label: 'ErrorG: Failure calling Marriott web service',
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorG" maskedMP={maskedMP} />
              </StyledSignup>
            )
          } else if (transferMiles.data.exception.exceptionCode === '220') {
            const event = {
              category: category,
              action: `transferMiles.data.exception.exceptionCode: ${transferMiles.data.exception.exceptionCode}`,
              label: 'ErrorE: Max miles limit reached',
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorE" maskedMP={maskedMP} />
              </StyledSignup>
            )
          } else {
            // Error out
            const event = {
              category: category,
              action: `transferMiles.data.exception.exceptionCode: else case`,
              label: `ErrorK: Transfer Error out`,
              value: 0,
            }
            trackEvent(event)
            return (
              <StyledSignup>
                <LeftColumn />
                <RightColumnMessages profile={profile} elite={elite_levels} message="ErrorK" maskedMP={maskedMP} />
              </StyledSignup>
            )
          }
        } else if (convertMiles.convertMiles) {
          const event = {
            category: category,
            action: 'Confirm Page',
            label: 'Confirm Form',
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnConfirm
                authCode={authCode}
                profile={profile}
                marriott={mr}
                convertMiles={convertMiles}
                elite={elite_levels}
                maskedMP={maskedMP}
                maskedMR={maskedMR}
              />
            </StyledSignup>
          )
        } else if (profile.marriottNumber) {
          // const maskedMRprofile = maskAccount(profile.marriottNumber);
          // const mr = { "marriottNumber": profile.marriottNumber }
          const event = {
            category: category,
            action: 'ConvertMiles Page',
            label: 'ConvertMiles Form',
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnConvert profile={profile} marriott={mr} elite={elite_levels} maskedMP={maskedMP} maskedMR={maskedMR} />
            </StyledSignup>
          )
        } else {
          const event = {
            category: category,
            action: 'ConvertMiles Page',
            label: 'ConvertMiles Form',
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnConvert profile={profile} marriott={mr} elite={elite_levels} maskedMP={maskedMP} maskedMR={maskedMR} />
            </StyledSignup>
          )
        }
      } else {
        const event = {
          category: category,
          action: 'Marriott Page',
          label: 'Marriott Form',
          value: 0,
        }
        trackEvent(event)
        fraudDetection(profile.kountSessionID)
        return (
          // We can ask for User's Marriott Number
          <StyledSignup>
            <LeftColumn />
            <RightColumnMarriott profile={profile} elite={elite_levels} maskedMP={maskedMP} />
          </StyledSignup>
        )
      }
    }
    if (authCode) {
      return (
        <StyledSignup>
          <LeftColumn />
          <RightColumnLoader />
        </StyledSignup>
      )
    }
    return (
      <StyledSignup>
        <LeftColumn />
        <RightColumnSignin />
      </StyledSignup>
    )
  }
}

export default Miles
