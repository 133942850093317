import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'

import styled from 'styled-components'

import FormContainer from '../../common/formContainer'
import SubTitle from '../../common/formSubTitle'
import Title from '../../common/formTitle'
import Disclaimer from './disclaimer'
import Loader from '../../loader/loader'

const StyledName = styled.span`
  white-space: nowrap;
`

class RightColumnLoader extends Component {
  render() {
    return (
      <FormContainer id="RightColumn">
        <div>
          <Title>
          <Trans i18nKey="signup.right.signup.title">
              ..
              <StyledName>..</StyledName>
              <sup>..</sup>
              <StyledName>..</StyledName>
              ..
            </Trans>
          </Title>
          <SubTitle>
            <Trans i18nKey="signup.right.signup.subtitle">
              Sign up for Marriott Rewards
              <sup>®</sup>, The Ritz-Carlton Rewards
              <sup>®</sup>, and SPG
              <sup>®</sup> <br />
              Gold Elite status
            </Trans>
          </SubTitle>

          {/* FORM */}
          <Loader />
        </div>
        <Disclaimer />
      </FormContainer>
    )
  }
}

export default translate('translation')(RightColumnLoader)
