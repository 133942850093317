import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'
import OpenInNew from '@material-ui/icons/OpenInNew'

const StyledFooter = styled.div`
  background: #0c2340;
  color: #ffffff;
  width: 100%;
  min-height: 100px;
  @media (min-width: ${props => props.theme.minWidth.giga}) {
    min-height: 150px;
  }
`

const StyledList = styled.ul`
  margin: 0px;
  padding: 0px 0px 0px 0px;
  text-align: left;
  transition-duration: 320ms;
  transition-property: height;
  overflow: hidden;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    margin: 0px auto;
    padding: 28px 0px 0px 0px;

    display: table !important;
    table-layout: fixed;

    & > li {
      display: table-cell;
    }
  }
`

const StyledListItem = styled.li`
  list-style-type: none;
  font-size: 12px;
  line-height: 17px;
  padding: 12px 16px;
  border-bottom: 1px solid #ffffff5c;

  &:hover {
    opacity: 0.8;
    background: #666;
  }

  & > a {
    display: block;
    color: #ffffff;
  }

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 0px 10px;
    border: none;

    &:hover {
      opacity: 1;
      background: none;
    }

    & > a {
      display: block;

      color: #ffffff;
      text-decoration: none;

      &:hover {
        color: #ffffff;
        text-decoration: underline;
        cursor: pointer;
      }

      div > & {
        text-decoration: underline;
      }
    }
  }
`

const StyledLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;

  &:hover {
    color: #ffffff;
    text-decoration: underline;
    cursor: pointer;
  }

  div > & {
    text-decoration: underline;
  }
`

const FooterCaption = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 1.6;
  text-align: center;
  padding: 30px 8px;

  & > a {
    color: #ffffff;
    text-decoration: underline;

    &:hover {
      color: #ffffff;
      text-decoration: none;
      cursor: pointer;
    }
  }

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 35px 8px 12px 8px;
  }
`

class Footer extends Component {
  render() {
    const t = this.props.t

    return (
      <StyledFooter id="Footer">
        <StyledList>
          <StyledListItem>
            <StyledLink to="terms">{t('footer.navigation.text.preferredPartnershipTerms')}</StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to="faqs">{t('footer.navigation.text.preferredPartnershipFAQs')}</StyledLink>
          </StyledListItem>
          <StyledListItem>
            <a
              href={t('footer.navigation.uri.mileagePlus')} 
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.navigation.text.mileagePlus')}
            </a>
          </StyledListItem>
          <StyledListItem>
            <a
              href={t('footer.navigation.uri.mileagePlusProgramRules')} 
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.navigation.text.mileagePlusProgramRules')}
            </a>
          </StyledListItem>
          <StyledListItem>
            <a
              href={t('footer.navigation.uri.united')} 
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.navigation.text.united')}
            </a>
          </StyledListItem>
          <StyledListItem>
            <a
              href={t('footer.navigation.uri.unitedPrivacyPolicy')} 
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.navigation.text.unitedPrivacyPolicy')}
            </a>
          </StyledListItem>
          <StyledListItem>
            <a
              href={t('footer.navigation.uri.marriottPrivacyPolicy')} 
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.navigation.text.marriottPrivacyPolicy')}{' '}
              <OpenInNew style={{ verticalAlign: 'text-bottom', marginLeft: '3px' }} />
            </a>
          </StyledListItem>
        </StyledList>
        <FooterCaption>
          <Trans i18nKey="footer.navigation.text.signup">
            ...
            <a
              href={t('footer.navigation.uri.signup')} 
              target="_blank"
              rel="noopener noreferrer"
            >
              ...
            </a>
            ...
          </Trans>
          <OpenInNew style={{ verticalAlign: 'text-bottom', marginLeft: '3px' }} />
        </FooterCaption>
      </StyledFooter>
    )
  }
}

export default translate('translation')(Footer)
