import React, { Component } from 'react'
import styled from 'styled-components'
import { Trans, translate } from 'react-i18next'

import { Title } from '../common/title'
import { Paragraph } from '../common/paragraph'

const StyledLeftColumn = styled.div`
  background-color: #ffffff;
  opacity: 90%;
  width: 100%;
  padding: 30px;
  margin: 0 0 25px 0;
  flex: 1 1 300px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: 490px;
    margin: 0px 25px 0px 0px;
  }
`

const StyledName = styled.span`
  white-space: nowrap;
`

class LeftColumn extends Component {
  render() {
    const { t } = this.props

    return (
      <StyledLeftColumn id="LeftColumn">
        <Title style={{ fontWeight: '600' }}>
          <Trans i18nKey="signup.left.title">
            ..
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
          </Trans>
        </Title>
        <Paragraph>
          <Trans i18nKey="signup.left.text">
            ..
            <StyledName>..</StyledName>
            <sup>..</sup>
            ..
            <sup>..</sup>
            ..
            <StyledName>..</StyledName>
            ..
            <sup>..</sup>
            ..
            {/* Below are for Japanese translation */}
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
          </Trans>
        </Paragraph>
      </StyledLeftColumn>
    )
  }
}

export default translate('translation')(LeftColumn)
