// Constants:
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
const MP_REGEX = /^([a-zA-Z]{2}\d{6}|[a-zA-Z]{3}\d{5})$/

// Validator Functions:
// Length:
export const setLength = set => value => (value && value.length !== set ? `validators.setLength${set}` : undefined)

// Numbers:
export const maxValue = max => value => (value > max ? `validators.maxValue_${max}` : undefined)
export const minValue = min => value => (value < min ? `validators.minValue_${min}` : undefined)
export const isNumeric = value => (value && isNaN(Number(value)) ? 'validators.isNumeric' : undefined)

// Other:
export const required = value => (value ? undefined : 'validators.required')

// Pattern:
export const validEmail = value => (!value || EMAIL_REGEX.test(value) ? undefined : 'validators.validEmail')
export const validMPAccount = value => (!value || MP_REGEX.test(value) ? undefined : 'validators.validMPAccount')

// Exports:
export const setLength9 = setLength(9)
export const maxValue_100000 = maxValue(100000)
export const minValue_500 = minValue(500)
