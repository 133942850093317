import React, { Component } from 'react'
import styled from 'styled-components'
import { Trans, translate } from 'react-i18next'

import { Title } from '../common/title'
import { Paragraph } from '../common/paragraph'

import i18next from 'i18next'

const StyledCenterColumn = styled.div`
  background-color: #ffffff;
  opacity: 90%;
  height: fit-content;
  width: 100%;
  padding: 30px;
  margin: 0 0 25px 0;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: 900px;
    margin: 0px 25px 0px 0px;
  }
`

const StyledName = styled.span`
  white-space: nowrap;
`

const StyleLink = styled.a`
  white-space: nowrap;
`

class CenterColumn extends Component {
  render() {
    const { t } = this.props

    return (
      <StyledCenterColumn id="CenterColumn">
        <Title style={{ fontWeight: '600' }}>
          <Trans i18nKey="signup.center.title">
            ...
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            ..
          </Trans>
        </Title>
        <Paragraph>
          <Trans i18nKey="signup.center.text">
            ..
            <StyledName>..</StyledName>
            ..
            <StyledName>..</StyledName>
            ..
            {/* Below are for Japanese translation */}
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyleLink href="#signup">..</StyleLink>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
            <StyledName>..</StyledName>
          </Trans>
        </Paragraph>
      </StyledCenterColumn>
    )
  }
}

export default translate('translation')(CenterColumn)
