import React, { Component } from 'react'
import Container from './Container'

import styled from 'styled-components'

import i18next from 'i18next'

import BannerComponent from '../components/banner/banner'

const StyledBanner = styled.div`
  // display: ${props => (/^en/.test(i18next.language) ? 'block' : 'none')};
  background-color: #6244baff;
  position: fixed;
  top: 75px;
  width: 100%;
  z-index: 9998;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    top: 115px;
  }
`

class Banner extends Component {
  render() {

    const isTopBanner = this.props.isTopBanner

    return (
      <StyledBanner>
        <Container {...this.props}>
          <BannerComponent isTopBanner={isTopBanner} />
        </Container>
      </StyledBanner>
    )
  }
}

export default Banner
