import React, { Component } from 'react'
import styled from 'styled-components'
import { Trans, translate } from 'react-i18next'

import Container from '../../containers/Container'

import deskopBannerImgUrl from '../../images/promo_2024_july_aug_desktop_900.jpg'
import mobileBannerImgUrl from '../../images/promo_2024_july_aug_mobile_468.jpg'

import ButtonLink from '../common/buttonLink'

import i18next from 'i18next'

const StyledPromoContainer = styled.div`
  display: ${props => (/^en/.test(i18next.language) ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: 20px;
  justify-content: center;
  align-items: center;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    justify-content: flex-start;
    align-items: normal;
  }
`
const StyledPromoTextContainer = styled.div`
  max-width: 468px;
  background-color: #1514d2;
  color: #fff;
  padding: 0 20px 20px 20px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    max-width: 900px;
    width: 900px;
    padding: 0 35px 20px 35px;
  }
`

const StyledPromoImgContainer = styled.div`
`
const BannerMobileImage = styled.img`
  max-width: 100%;
  min-width: 350px;
  height: auto;

  display: block;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    display: none;
  }
`

const StyledHeader = styled.h3`
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    font-size: 40px;
    line-height: 45px;
  }
`

const StyledText = styled.div`
  color: #fff
  font-size: 15px;
  line-height: 25px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    font-size: 30px;
    line-height: 40px;
  }
`

const StyledName = styled.span`
  white-space: nowrap;
`

const BannerDesktopImage = styled.img`
  max-width: 100%;
  height: auto;

  display: none;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    display: block;
  }
`

const PromoButton = styled.div`
  display: block;
  text-align: right;
  margin-top: 35px;

  @media (min-width: ${props => props.theme.minWidth.nano}) {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-end;
  }
`

const TermsApply = styled.div`
  color: #fff
  font-size: 12px;
  line-height: 30px;
  white-space: nowrap;

  @media (min-width: ${props => props.theme.minWidth.nano}) {
    position: absolute;
    bottom: 0;
    font-size: 15px;
  }

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    position: absolute;
    bottom: 0;
    font-size: 15px;
  }
`

export class Promotion extends Component {
  render() {
    const { t } = this.props

    return (
      <Container style={{}}>

        <StyledPromoContainer id="new-promotion">
          <StyledPromoImgContainer>
            <BannerDesktopImage src={deskopBannerImgUrl} />
            <BannerMobileImage src={mobileBannerImgUrl} />
          </StyledPromoImgContainer>
          <StyledPromoTextContainer>
            <StyledHeader>
              <Trans i18nKey="promotion.title">
              <StyledName>..</StyledName>
              ..
              <StyledName>..</StyledName>
            </Trans>
            </StyledHeader>
            <StyledText>
              <Trans i18nKey="promotion.text">
              ..
              <br />
              ..
              </Trans>
            </StyledText>
            <PromoButton>
              <ButtonLink target={t('promotion.uri')} text={t('promotion.buttonText')} width={'285px'} backgroundColor={'#fff'} color={'#1514d2'} fontSize={'20px'} fontWeight={'bold'} borderColor={'#fff'} borderRadius={'50px'} openInNew={true} targetBlank={true}/>
              <TermsApply>
                <Trans i18nKey="promotion.termsApply" />
            </TermsApply>
            </PromoButton>
            
          </StyledPromoTextContainer>
        </StyledPromoContainer>

      </Container>
    )
  }
}

export default translate('translation')(Promotion)
