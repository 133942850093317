import { v4 as uuid } from 'uuid';

export const Actions = {
  login: 'login/LOGIN',
  logout: 'logout/LOGOUT',
  getProfile: 'login/GET_PROFILE',
  getProfileSuccess: 'login/GET_PROFILE_SUCCESS',
  getProfileFail: 'login/GET_PROFILE_FAIL',
  marriottNumber: 'login/MARRIOTT_NUMBER',
  overrideMarriottNumber: 'login/OVERRIDE_MARRIOTT_NUMBER',
  nominate: 'nominate/NOMINATE_MEMBER',
  nominateSuccess: 'nominate/NOMINATE_MEMBER_SUCCESS',
  nominateFail: 'nominate/NOMINATE_MEMBER_FAIL',
  miles: 'miles/CONVERT_MILES',
  transfer: 'transfer/TRANSFER_MILES',
  transferSuccess: 'transfer/TRANSFER_MILES_SUCCESS',
  transferFail: 'transfer/TRANSFER_MILES_FAIL',
}

export const maskAccount = account => {
  if (account.length === 0) {
    return account
  }
  return 'xxxx' + account.substring(4)
}

export const login = queryParameters => {
  return {
    type: Actions.login,
    payload: {
      onlineAuthCode: queryParameters.onlineAuthCode,
      offlineAuthCode: queryParameters.offlineAuthCode,
    },
  }
}

export const logout = ()  => {
  return {
    type: Actions.logout,
  }
}

export const getProfile = authCode => {
  return {
    type: Actions.getProfile,
    payload: {
      request: {
        method: 'get',
        url: '/profile',
        params: {
          authenticationCode: authCode,
          SID: process.env.REACT_APP_SID,
        },
      },
    },
  }
}

export const marriottNumber = formData => {
  return {
    type: Actions.marriottNumber,
    payload: {
      marriottNumber: formData,
    },
  }
}

export const overrideMarriottNumber = marriottNumber => {
  return {
    type: Actions.overrideMarriottNumber,
    payload: {
      marriottNumber: marriottNumber,
    },
  }
}

const generateTransactionId = () => {
  return uuid();
}

export const nominateMember = (authCode, marriottNumber) => {
  return {
    type: Actions.nominate,
    payload: {
      request: {
        method: 'post',
        url: '/nominate',
        params: {
          authenticationCode: authCode,
          SID: process.env.REACT_APP_SID,
        },
        data: {
          transactionID: generateTransactionId(),
          marriottNumber: marriottNumber.marriottNumber,
        }
      },
    },
  }
}

export const convertMiles = miles => {
  return {
    type: Actions.miles,
    payload: {
      convertMiles: miles,
    },
  }
}

export const transferMiles = (authCode, marriottNumber, miles, kountSessionID) => {
  return {
    type: Actions.transfer,
    payload: {
      request: {
        method: 'post',
        url: '/transfer',
        params: {
          authenticationCode: authCode,
          SID: process.env.REACT_APP_SID,
        },
        data: {
          marriottNumber: marriottNumber.marriottNumber,
          miles: miles.convertMiles,
          kountSessionID: kountSessionID,
        },
      },
    },
  }
}
