import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import renderField from '../../../form/renderField'
import Button from '../../../common/button'
import FormText from '../../../common/formText'

import i18next from 'i18next'

const Anchor = styled.a`
  color: #006080;
`

export class SigninForm extends Component {
  constructor(props) {
    super(props)
  }

  renderForm() {
    const t = this.props.t

    let milesCID = process.env.REACT_APP_MILES_CID

    if (/^es/.test(i18next.language)) {
      milesCID = process.env.REACT_APP_ES_MILES_CID
    } else if (/^pt/.test(i18next.language)) {
      milesCID = process.env.REACT_APP_PT_MILES_CID
    } else if (/^jp/.test(i18next.language)) {
      milesCID = process.env.REACT_APP_JP_MILES_CID
    }

    const LOGIN_URL = process.env.REACT_APP_CONNECT_URL

    return (
      <form name="signinForm" action={LOGIN_URL}>
        <input type="hidden" name="CID" value={milesCID} />
       
        <Field name="accept_terms" label={t('miles.right.signin.form.accept_terms')} component={renderField} type="checkbox">
          <FormText>
            <Trans i18nKey="miles.right.signin.form.accept_terms">
              I accept the <Anchor href="terms">terms and conditions</Anchor>
            </Trans>
          </FormText>
        </Field>

        <Button type="submit" alt="Sign In">
          {t('miles.right.signin.form.button')}
        </Button>
      </form>
    )
  }

  render() {
    return this.renderForm()
  }
}

// Decorate the form component with redux-form
const SignInForm = reduxForm({
  form: 'signinForm', // a unique name for this form
})(SigninForm)

export default translate('translation')(SignInForm)
