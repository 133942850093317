import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'

import styled from 'styled-components'

import Disclaimer from './disclaimer'
import SignupForm from './forms/signupForm'

import FormContainer from '../../common/formContainer'
import SuperTitle from '../../common/formSuperTitle'
import Title from '../../common/formTitle'

const StyledName = styled.span`
  white-space: nowrap;
`

class RightColumnSignup extends Component {
  render() {
    const t = this.props.t

    return (
      <FormContainer id="RightColumn">
        <div>
          <SuperTitle>{t('signup.right.signup.supertitle')}</SuperTitle>
          <Title>
            <Trans i18nKey="signup.right.signup.title">
              ..
              <StyledName>..</StyledName>
              <sup>..</sup>
              <StyledName>..</StyledName>
              ..
            </Trans>
          </Title>

          {/* FORM */}
          <SignupForm />
          <Disclaimer />
        </div>
        
      </FormContainer>
      
    )
  }
}

export default translate('translation')(RightColumnSignup)
