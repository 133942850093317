import React, { Component } from 'react'
import { translate } from 'react-i18next'

import SigninForm from './forms/signinForm'

import { RightColumn as RightColumnTemplate } from '../../common/rightColumn'
import FormContainer from '../../common/formContainer'
import SuperTitle from '../../common/formSuperTitle'
import Title from '../../common/formTitle'

class RightColumnSignin extends Component {
  render() {
    const t = this.props.t

    return (
      <RightColumnTemplate id="RightColumn">
        <FormContainer id="RightColumnForm">
          <div>
            <SuperTitle>{t('miles.right.signin.supertitle')}</SuperTitle>
            <Title>{t('miles.right.signin.title')}</Title>

            {/* FORM */}
            <SigninForm />
          </div>
        </FormContainer>
      </RightColumnTemplate>
    )
  }
}

export default translate('translation')(RightColumnSignin)
