import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import renderField from '../../../form/renderField'
import Button from '../../../common/button'
import FormText from '../../../common/formText'

import i18next from 'i18next'

const Anchor = styled.a`
  & > a {
    color: #006080;
  }
`

export class SignupForm extends Component {
  constructor(props) {
    super(props)
  }

  renderForm() {
    const t = this.props.t

    let signupCID = process.env.REACT_APP_SIGNUP_CID

    if (/^es/.test(i18next.language)) {
      signupCID = process.env.REACT_APP_ES_SIGNUP_CID
    } else if (/^pt/.test(i18next.language)) {
      signupCID = process.env.REACT_APP_PT_SIGNUP_CID
    } else if (/^jp/.test(i18next.language)) {
      signupCID = process.env.REACT_APP_JA_SIGNUP_CID
    }

    const LOGIN_URL = process.env.REACT_APP_CONNECT_URL

    return (
      <form name="signupForm" action={LOGIN_URL}>
        <input type="hidden" name="CID" value={signupCID} />

        <Field
          name="accept_terms"
          label={t('signup.right.signup.form.accept_terms')}
          formtype="Signup"
          component={renderField}
          type="checkbox"
        >
          <FormText>
            <Trans i18nKey="signup.right.signup.form.accept_terms">
              I accept the <Anchor href="terms">terms and conditions</Anchor>
            </Trans>
          </FormText>
        </Field>

        <Button type="submit" alt="Sign In">
          {t('signup.right.signup.form.button')}
        </Button>
      </form>
    )
  }

  render() {
    return this.renderForm()
  }
}

// Decorate the form component with redux-form
const SignUpForm = reduxForm({
  form: 'signupForm', // a unique name for this form
  //validate: validateAddIp,
})(SignupForm)

export default translate('translation')(SignUpForm)
