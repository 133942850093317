import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { translate } from 'react-i18next'
import styled from 'styled-components'

import { nominateMember, overrideMarriottNumber } from '../../../../actions'

import FormText from '../../../common/formText'
import Button from '../../../common/button'

const StyledLink = styled.span`
  color: #006080;
  cursor: pointer;

  &:hover,
  :focus {
    text-decoration: underline;
  }
`

export class ConfirmForm extends Component {
  static contextTypes = { store: PropTypes.object }
  handleClick = event => {
    const { marriott } = this.props
    const {
      store: { dispatch },
    } = this.context
    dispatch(overrideMarriottNumber(marriott.marriottNumber))
  }
  renderForm() {
    const t = this.props.t
    const { handleSubmit, submitting } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <FormText>
          <StyledLink id="notmr" onClick={this.handleClick}>
            {t('signup.right.confirm.form.not_mr')}
          </StyledLink>
        </FormText>

        <Button type="submit" disabled={submitting} alt="Sign Up">
          {t('signup.right.confirm.form.button')}
        </Button>
      </form>
    )
  }

  render() {
    return this.renderForm()
  }
}

// Decorate the form component with redux-form
const ConfrimReduxForm = reduxForm({
  form: 'confirmForm', // a unique name for this form
})(ConfirmForm)

// Decorate the form component with connect
const mapStateToProps = (state, ownProps) => {
  return {
    //...state,
  } // when in doubt pass nothing
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (formData, dispatch, props) => {
      dispatch(props.startSubmit)
      dispatch(nominateMember(props.authCode, props.marriott))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translation')(ConfrimReduxForm))
