import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import Container from './Container'
import SignUp from './Signup'
import { login, getProfile } from '../actions'

const captionMargin = '24px 0px 24px 0px;'

const isEmpty = obj => {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }
  return true
}

class SignupContainer extends Component {
  componentDidMount() {
    const authCode = this.props.authCode
    if (isEmpty(authCode)) {
      const values = queryString.parse(this.props.location.search)
      // this.props.login(values)

      if (!isEmpty(values) && values.onlineAuthCode) {
        this.props.login(values)
        this.props.getProfile(values.onlineAuthCode)
      }
    }
  }

  render() {
    return (
      <Container background={this.props.containerBackground} captionMargin={captionMargin} {...this.props}>
        <SignUp
          authCode={this.props.authCode}
          profile={this.props.profile}
          marriott={this.props.marriott}
          nominate={this.props.nominate}
          notmr={this.props.location.pathname === '/notmr' ? true : false}
        />
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authCode: state.Store.login,
    profile: state.Store.profile,
    marriott: state.Store.marriott,
    nominate: state.Store.nominate,
    ...state,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: queryParameters => dispatch(login(queryParameters)),
    getProfile: authCode => dispatch(getProfile(authCode)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupContainer)
