import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { Trans, translate } from 'react-i18next'
import i18next from 'i18next'
import styled from 'styled-components'

import Header from '../header/header'
import Footer from '../footer/footer'

import background from '../../images/FAQ_background.jpg'

const TermsContainer = styled.div`
  display: inline-block;
  background: url(${background});
  background-size: cover;
  width: 100%;
`

const StyledTerms = styled.div`
  background: #ffffff;
  color: #000000;
  font-family: ${props => (/^jp/.test(i18next.language) ? props.theme.font.jp : props.theme.font.default)};

  max-width: ${props => props.theme.desktop.contentWidth};
  min-height: 800px;

  transition-property: width, margin;
  transition-duration: 0.5s;

  margin: 75px auto 0px;
  padding-bottom: 30px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    margin: 200px auto 85px;
  }
`

const TermsTitle = styled.div`
  font-size: 36px;
  line-height: 46px;
  font-weight: 300;
  padding: 59px 10px 15px 24px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 59px 0px 35px 44px;
  }
`

const NoteTitle = styled.div`
  padding: 0px 28px 5px 28px;
  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 0px 0px 5px 44px;
  }
`

const Note = styled.div`
  padding: 0px 28px 15px 28px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 0px 66px 15px 44px;
  }
`

const Intro = styled.div`
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  padding: 20px 28px 20px 28px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 20px 66px 20px 48px;
  }
`

const Subtitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  padding: 5px 10px 5px 24px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 5px 66px 5px 48px;
  }
`

const Term = styled.div`
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  padding: 20px 28px 20px 28px;

  & > a {
    color: #000000;
    text-decoration: none;

    &:hover {
      color: #002244;
      text-decoration: underline;
    }
  }

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 20px 66px 20px 48px;
  }
`

const Subterm = styled.div`
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  padding: 0px 50px 20px 41px;

  & > a {
    color: #000000;
    text-decoration: none;

    &:hover {
      color: #002244;
      text-decoration: underline;
    }
  }

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 0px 116px 20px 68px;
  }
`

const SubSubterm = styled.div`
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  padding: 0px 50px 20px 52px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 0px 126px 20px 88px;
  }
`

const Outro = styled(Term)``

class Terms extends Component {
  // static contextTypes = {
  //    t: PropTypes.func,
  // };

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    //const t = this.context.t;
    const t = this.props.t
    return (
      <TermsContainer>
        <Header />
        <StyledTerms id="Terms">
          <TermsTitle>{t('terms.title')}</TermsTitle>
          <NoteTitle>{t('terms.note_title')}</NoteTitle>
          <Note>
            <Trans i18nKey="terms.note">
              Marriott offers travelers a choice of three frequent travel programs: the Marriott Rewards
              <sup>®</sup> Program, The Ritz-Carlton Rewards Program and the Starwood Preferred Guest
              <sup>®</sup> Program. Effective August 2018, Marriott Rewards, The Ritz-Carlton Rewards, and the SPG will keep their
              separate names for the time being but will function as one program (the “Marriott Loyalty Program”) operating under the
              same terms and conditions.
            </Trans>
          </Note>
          <Intro>
            <Trans i18nKey="terms.intro">
              NOTE: These terms and conditions (the "RewardsPlus Rules") form the basis of the RewardsPlus Program offered by the
              Marriott Bonvoy
              <sup>TM</sup> loyalty program (the “Marriott Loyalty Program”) and MileagePlus
              <sup>®</sup>. Your registration and participation in the RewardsPlus Program will be governed by the RewardsPlus Rules,
              the “MileagePlus Program Rules” and the “Marriott Loyalty Program Rules” (as such terms are defined below), which are
              your responsibility to read and understand. By submitting a registration request for, participating in or availing
              yourself of the benefits of the RewardsPlus Program, you agree to be bound by the RewardsPlus Rules, the MileagePlus
              Program Rules and the Marriott Loyalty Program Rules.
            </Trans>
          </Intro>

          <Subtitle>{t('terms.general.title')}</Subtitle>
          <Term>{t('terms.general.one')}</Term>
          <Term>
            <Trans i18nKey="terms.general.two">
              2. Miles accrued, awards, and benefits issued, including those issued pursuant to the RewardsPlus Program, are subject
              to change and are subject to the rules (“MileagePlus Program Rules”) of the United MileagePlus program, including
              without limitation the Premier
              <sup>®</sup> program (the “MileagePlus Program”), which are expressly incorporated herein. MileagePlus has the right to
              terminate the MileagePlus Program or to change the MileagePlus Program Rules, regulations, policies, procedures,
              conditions of participation, benefits, awards, and special offers, in whole or in part, at any time, with or without
              notice.
            </Trans>
          </Term>
          <Term>{t('terms.general.three')}</Term>
          <Term>{t('terms.general.four')}</Term>
          <Term>{t('terms.general.five')}</Term>
          <Term>{t('terms.general.six')}</Term>
          <Term>{t('terms.general.seven')}</Term>
          <Term>{t('terms.general.eight')}</Term>
          <Term>{t('terms.general.nine.title')}</Term>
          <Subterm>
            <Trans i18nKey="terms.general.nine.item_a">
              a. In the MileagePlus Program: Members who receive complimentary Premier
              <sup>®</sup> Silver status in the MileagePlus Program through the RewardsPlus Program may reach higher Premier status
              levels, but only by reaching the published qualification requirements as set forth in the MileagePlus Premier program.
              Premier Silver status in the MileagePlus Program that is granted through the RewardsPlus Program may not be combined,
              added or stacked with any premier qualifying miles, premier qualifying segments, premier qualifying dollars or other
              qualification requirements to achieve a higher Premier status level in the MileagePlus Program. For more information,
              please visit
              <a
                href={t('terms.uri.nine.premier')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                www.united.com/premier
              </a>
              .
            </Trans>
          </Subterm>
          <Subterm>
            <Trans i18nKey="terms.general.nine.item_b">
              b. In the Marriott Loyalty Program: Members who receive complimentary Gold Elite status in the Marriott Loyalty Program
              through the RewardsPlus Program may reach higher Elite status levels, but only by reaching the published qualification
              requirements as set forth in the Marriott Loyalty Program Rules. Gold Elite status in the Marriott Loyalty Program that
              is granted through the RewardsPlus Program may not be combined, added or stacked with any elite qualification
              requirements to achieve a higher Elite status level in the Marriott Loyalty Program. For more information, please visit
              <a
                href={t('terms.uri.nine.member-benefits')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                www.marriott.com/rewards/member-benefits.mi
              </a>
              .
            </Trans>
          </Subterm>
          <Term>{t('terms.general.ten')}</Term>
          <Term>
            <Trans i18nKey="terms.general.eleven">
              11. By participating in the RewardsPlus Program, you consent to the Marriott Loyalty Program and MileagePlus each
              sharing and receiving your MileagePlus and the Marriott Loyalty Program member data with and from the Marriott Loyalty
              Program and MileagePlus and their respective subsidiaries, affiliates and agents. Such data will be used by the Marriott
              Loyalty Program and MileagePlus in connection with the RewardsPlus Program and will be used individually by the Marriott
              Loyalty Program and MileagePlus in accordance with their respective privacy policies, which can be found at
              <a
                href={t('terms.uri.eleven.united_privacy_policy')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.united.com/web/en-US/content/privacy.aspx
              </a>
              and
              <a
                href={t('terms.uri.eleven.marriott_privacy_policy')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.marriott.com/marriott/privacy-us.mi
              </a>
              .
            </Trans>
          </Term>
          <Term>{t('terms.general.twelve')}</Term>
          <Term>{t('terms.general.thirteen_new')}</Term>
          <Term>
            <Trans i18nKey="terms.general.thirteen">
              13. Information contained herein that relates to the MileagePlus Program or the Marriott Loyalty Program does not
              purport to be complete or comprehensive and may not include all of the information that a member may believe is
              important, and is qualified in its entirety by reference to all of the information on the United.com, and the website
              for Marriott Bonvoy, Marriott International, Inc., the MileagePlus Program Rules and the Marriott Loyalty Program Rules.
              For complete details about the MileagePlus Program, go to
              <a
                href={t('terms.uri.thirteen.united')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                www.united.com
              </a>
              , and for complete details about the Marriott Loyalty Program, go to
              <a
                href={t('terms.uri.thirteen.marriott_rewards')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.marriott.com/loyalty.mi
              </a>
              ,
              <a
                href={t('terms.uri.thirteen.ritz_rewards')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.Ritz-Carlton.com/en/rewards
              </a>
              and
              <a
                href={t('terms.uri.thirteen.spg_preffered')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.StarwoodHotels.com/preferredguest
              </a>
              .
            </Trans>
          </Term>
          <Term>{t('terms.general.fourteen')}</Term>
          <Term>
            <Trans i18nKey="terms.general.sixteen">
              MileagePlus terms and conditions: Miles accrued, awards, and benefits issued are subject to change and are subject to
              the rules of the United MileagePlus
              <sup>®</sup> program, including without limitation the Premier program, (the "MileagePlus Program") which are expressly
              incorporated herein. Please allow 6-8 weeks after completed qualifying activity for miles to post to your account.
              United United may change the MileagePlus Program including, but not limited to, rules, regulations, travel awards and
              offers or terminate the MileagePlus Program at any time and without notice. United and its subsidiaries, affiliates and
              agents are not responsible for any products or services of other participating companies and partners. Taxes and fees
              related to award travel are the responsibility of the member. Bonus award miles, award miles and any other miles earned
              through non-flight activity do not count toward qualification for Premier status unless expressly stated otherwise. The
              accumulation of mileage or Premier status or any other status does not entitle members to any vested rights with respect
              to the MileagePlus Program. All calculations made in connection with the MileagePlus Program, including without
              limitation with respect to the accumulation of mileage and the satisfaction of the qualification requirements for
              Premier status, will be made by United Airlines and MileagePlus in their discretion and such calculations will be
              considered final. Information in this communication that relates to the MileagePlus Program does not purport to be
              complete or comprehensive and may not include all of the information that a member may believe is important, and is
              qualified in its entirety by reference to all of the information on the
              <a
                href={t('terms.uri.thirteen.united')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                united.com
              </a>
              website and the MileagePlus Program rules. United and MileagePlus are registered service marks. For complete detail
              about the MileagePlus Program, go to
              <a
                href={t('terms.uri.thirteen.united')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                united.com
              </a>
              .
            </Trans>
          </Term>
          <Term>{t('terms.general.seventeen')}</Term>

          <Subtitle>{t('terms.eligibilityA.title')}</Subtitle>
          <Term>{t('terms.eligibilityA.one')}</Term>
          <Term>{t('terms.eligibilityA.two.intro')}</Term>
          <Subterm>
            <Trans i18nKey="terms.eligibilityA.two.item_a">
              To register a request for complimentary Gold Elite status in the Marriott Loyalty Program, a MileagePlus member must
              hold an active, validated account with either Premier Gold, Premier Platinum or Premier 1K
              <sup>®</sup> status in the MileagePlus Program. If so validated by MileagePlus, the member will be able to register to
              request complimentary Gold Elite status in the Marriott Loyalty Program through the RewardsPlus Program. MileagePlus
              members who hold Premier Silver or are general members in the MileagePlus Program are not eligible to register for
              complimentary Gold Elite status or any other Elite status in the Marriott Loyalty Program through the RewardsPlus
              Program. Members who meet the Criteria because of a trial Premier status in MileagePlus, including without limitation a
              MileagePlus Premier status match challenge program, are not eligible for complimentary status through the RewardsPlus
              Program. Registration requests from members who otherwise meet the Criteria for complimentary status through the
              RewardsPlus Program will not receive such status if the member already holds equivalent or higher status in the Marriott
              Loyalty Program.
            </Trans>
          </Subterm>
          <Subterm>{t('terms.eligibilityA.two.item_b')}</Subterm>
          <Term>{t('terms.eligibilityA.three')}</Term>
          <Term>{t('terms.eligibilityA.four')}</Term>
          <Term>{t('terms.eligibilityA.five')}</Term>
          <Term>
            <Trans i18nKey="terms.eligibilityA.six">
              6. Members of the Marriott Loyalty Program who meet the Criteria for the RewardsPlus Program and who wish to enjoy the
              benefits of MileagePlus Premier Silver status must register their request through RewardsPlus at
              <a
                href={t('terms.uri.six.marriott_rewards_plus')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                www.getrewardsplus.com
              </a>
              .
            </Trans>
          </Term>
          <Term>
            <Trans i18nKey="terms.eligibilityA.seven">
              7. Members of the MileagePlus Program who meet the Criteria for the RewardsPlus Program and who wish to enjoy the
              benefits of Gold Elite status with the Marriott Loyalty Program must register through RewardsPlus at
              <a
                href={t('terms.uri.six.marriott_rewards_plus')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                www.getrewardsplus.com
              </a>
            </Trans>
          </Term>
          <Term>{t('terms.eligibilityA.eight')}</Term>

          <Subtitle>{t('terms.eligibilityB.title')}</Subtitle>
          <Term>{t('terms.eligibilityB.one')}</Term>
          <Term>{t('terms.eligibilityB.two')}</Term>
          <Term>{t('terms.eligibilityB.three.intro')}</Term>
          <Subterm>{t('terms.eligibilityB.three.item_a')}</Subterm>
          <SubSubterm>{t('terms.eligibilityB.three.item_a_a')}</SubSubterm>
          <SubSubterm>{t('terms.eligibilityB.three.item_a_b')}</SubSubterm>
          <SubSubterm>{t('terms.eligibilityB.three.item_a_c')}</SubSubterm>
          <Subterm>{t('terms.eligibilityB.three.item_b')}</Subterm>
          <Term>{t('terms.eligibilityB.four')}</Term>
          <Term>{t('terms.eligibilityB.five')}</Term>
          <Term>{t('terms.eligibilityB.six')}</Term>

          <Outro>{t('terms.outro')}</Outro>
        </StyledTerms>
        <Footer />
      </TermsContainer>
    )
  }
}

//export default Terms;
export default translate('translation')(Terms)
