import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'

import styled from 'styled-components'

import i18next from 'i18next'

const StyledBanner = styled.div`
  width: 100%;
  height: auto;
  
  margin: 5px 0px 10px 0px;
  color: #fff;
`

const StyledLink = styled.a`
  display: inline;
  color: #fff;
  text-decoration: underline;

  &:hover,
  &:focus{
    color: rgb(114, 201, 230);;
  }
`

const StyledStrongSup = styled.sup`
  font-weight: bold;
`

class Banner extends Component {
  render() {
    const t = this.props.t
    const isTopBanner = this.props.isTopBanner

    return (
      <StyledBanner isTopBanner={isTopBanner}>
        <Trans i18nKey="promotion.bannerText">
          ..
          <strong>..</strong>
          <StyledStrongSup>..</StyledStrongSup>
          ..
          <StyledLink href={t('promotion.signupUri')}>..</StyledLink>
          ..
          <StyledLink target="_blank" href={t('promotion.uri')}>{t('promotion.bannerCta')}</StyledLink>
          ..
        </Trans>
      </StyledBanner>
    )
  }
}

export default translate('translation')(Banner)
