import styled from 'styled-components'

import i18next from 'i18next'

export default styled.div`
  ${({ background }) =>
    background &&
    `
    background: url(${background}) no-repeat fixed;
  `} background-size: cover;
  background-attachment: scroll;
  font-family: ${props => (/^jp/.test(i18next.language) ? props.theme.font.jp : props.theme.font.default)};
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  min-width: 320px;
`
