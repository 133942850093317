import ReactGA from 'react-ga4'
import { Actions } from '../actions'

export const trackEvent = event => {
  const { category, action, label, value, nonInteraction = false } = event
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value,
    nonInteraction: nonInteraction,
  })
}

export const googleAnalytics = store => next => action => {
  const parts = action.type.split('/')
  const category = `Redux Actions: ${parts[0]}`
  const label = parts[1]

  if (action.type === Actions.login || action.type === Actions.marriottNumber || action.type === Actions.overrideMarriottNumber) {
    const event = {
      category: category,
      action: action.type,
      label: label,
      value: 0,
    }

    trackEvent(event)
  }

  if (action.type === Actions.getProfile || action.type === Actions.nominate || action.type === Actions.transfer) {
    const event = {
      category: category,
      action: action.type,
      label: label,
      value: 0,
    }

    trackEvent(event)
  }

  if (action.type === Actions.getProfileSuccess) {
    const event = {
      category: category,
      action: action.type,
      label: label,
      value: action.payload.data.memberLevel,
    }

    trackEvent(event)
  }

  if (action.type === Actions.miles) {
    const event = {
      category: category,
      action: action.type,
      label: label,
      value: parseInt(action.payload.convertMiles, 10),
    }

    trackEvent(event)
  }

  if (action.type === Actions.nominateSuccess) {
    const event = {
      category: category,
      action: action.type,
      label: label,
      value: 1,
    }

    trackEvent(event)
  }

  if (action.type === Actions.transferSuccess) {
    const event = {
      category: category,
      action: action.type,
      label: label,
      value: action.meta.previousAction.payload.request.data.miles,
    }

    trackEvent(event)
  }

  if (action.type === Actions.getProfileFail || action.type === Actions.nominateFail || action.type === Actions.transferFail) {
    const event = {
      category: category,
      action: action.type,
      label: label,
      value: action.type === Actions.transferFail ? action.meta.previousAction.payload.request.data.miles : 1,
    }

    trackEvent(event)
  }

  return next(action)
}
