import React, { Component } from 'react'
import styled from 'styled-components'
import { translate } from 'react-i18next'

import Container from '../../containers/Container'

import facebookIcon from '../../images/icon_facebook.png'
import twitterIcon from '../../images/icon_twitter.png'
import instagramIcon from '../../images/icon_instagram.png'

const SocialMediaLinksContent = styled.div`
  padding: 10px 80px;
  margin: 50px 0 0 0;
  font-size: 24px;
  text-align: center;
`

const SocialMediaLinksText = styled.p`
`

const Hashtag = styled.a`
  display: block;
  border: none;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    display: inline-block;
    padding-right: 15px;
    margin-right: 5px;
    border-right: solid;
    border-width: 1px;
  }
`

const SocialMediaLinksLogos = styled.div`
  display: inline-block;
`

const Link = styled.a`
  margin: 0px 15px;
`

const SocialMediaLogo = styled.img`
  height: 42px;
  width: 42px;
`

export class SocialMediaLinks extends Component {
  render() {
    const { t } = this.props

    return (
      <Container style={{}}>
        <SocialMediaLinksContent>

          <SocialMediaLinksLogos>
            <Link href={t('socialMediaLinks.twitterUri')}>
              <SocialMediaLogo src={twitterIcon} alt="twitter" />
            </Link>

            <Link href={t('socialMediaLinks.facebookUri')}>
              <SocialMediaLogo src={facebookIcon} alt="facebook" />
            </Link>

            <Link href={t('socialMediaLinks.instagramUri')}>
              <SocialMediaLogo src={instagramIcon} alt="instagram" />
            </Link>
          </SocialMediaLinksLogos>
        </SocialMediaLinksContent>
      </Container>
    )
  }
}

export default translate('translation')(SocialMediaLinks)
