import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import Disclaimer from './disclaimer'
import ConfirmForm from './forms/confirmForm'
import StyledRightColumn from '../../common/formContainer'
import Subtitle from '../../common/formSubTitle'
import Title from '../../common/formTitle'
import StyledText from '../../common/formText'

const StyledSpan = styled.span`
  font-weight: 500;
`

class RightColumnConfirm extends Component {
  render() {
    const t = this.props.t

    const authCode = this.props.authCode
    const marriott = this.props.marriott

    const firstName = this.props.profile.firstname
    const kountSessionID = this.props.profile.kountSessionID
    const maskedMP = this.props.maskedMP
    const maskedMR = this.props.maskedMR

    return (
      <StyledRightColumn id="RightColumn">
        <div>
          <Title>
            {t('signup.right.marriott.title')} {firstName},
          </Title>
          <Subtitle>
            <StyledSpan>
              <Trans i18nKey="signup.right.marriott.subtitle">
                MileagePlus
                <sup>®</sup> number:
              </Trans>
            </StyledSpan>
            &nbsp;
            {maskedMP}
          </Subtitle>
          <StyledText>
            <Trans i18nKey="signup.right.marriott.text">
              Congratulations. You're eligible to receive complimentary Marriott Bonvoy
              <sup>TM</sup> Gold Elite status.
            </Trans>
          </StyledText>
          <Subtitle>
            <StyledSpan>{t('signup.right.confirm.subtitle')}</StyledSpan>
            &nbsp;
            {maskedMR}
          </Subtitle>

          {/* FORM */}
          <ConfirmForm authCode={authCode} marriott={marriott} kountSessionID={kountSessionID} />
        </div>
        <Disclaimer />
      </StyledRightColumn>
    )
  }
}

export default translate('translation')(RightColumnConfirm)
