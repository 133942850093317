import { combineReducers } from 'redux'

import { Actions } from './actions'

const unknownError = {
    exception: {
      exceptionLocation: 'CR',
      exceptionCode: '999',
      exceptionMessage: 'Unknown Error',
  }
}

export const httpError = action => {
  return {
    error: action.error,
    data: action.error.response ? action.error.response.data : "",
    status: action.error.response ? action.error.response.status : 500,
    statusText: action.error.response ? action.error.response.statusText : "Unknown error",
  }
}

export const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case Actions.login:
      return {
        ...state,
        onlineAuthCode: action.payload.onlineAuthCode,
        offlineAuthCode: action.payload.offlineAuthCode,
      }
    default:
      return state
  }
}

export const logoutReducer = (state = {}, action) => {
  switch (action.type) {
    case Actions.logout:
      const logoutUrl = process.env.REACT_APP_LOGOUT_URL + '?CID=' + process.env.REACT_APP_SIGNUP_CID
      window.location.assign(logoutUrl)
      
      return {
        ...state,
        onlineAuthCode: '',
        offlineAuthCode: '',
      }
    default:
      return state
  }
}

export const profileReducer = (state = {}, action) => {
  switch (action.type) {
    case Actions.getProfile:
      return {
        loading: true,
      }
    case Actions.getProfileSuccess:
      return {
        data: action.payload.data,
      }
    case Actions.getProfileFail:
      return {
        ...state,
        loading: false,
        error: httpError(action),
      }
    default:
      return state
  }
}

export const marriottReducer = (state = {}, action) => {
  switch (action.type) {
    case Actions.marriottNumber:
      return {
        ...state,
        marriottNumber: typeof action.payload.marriottNumber.mr_number === 'string' ? action.payload.marriottNumber.mr_number.trim() : action.payload.marriottNumber.mr_number,
        overrideMarriottNumber: false,
      }
    case Actions.overrideMarriottNumber:
      return {
        ...state,
        marriottNumber: typeof action.payload.marriottNumber.mr_number === 'string' ? action.payload.marriottNumber.mr_number.trim() : action.payload.marriottNumber.mr_number,
        overrideMarriottNumber: true,
      }
    default:
      return state
  }
}

export const nominateReducer = (state = {}, action) => {
  switch (action.type) {
    case Actions.nominate:
      return {
        nominating: false,
      }
    case Actions.nominateSuccess:
      return {
        nominating: true,
        isNominated: true,
        data: action.payload.data,
      }
    case Actions.nominateFail:
      return {
        ...state,
        nominating: true,
        data: action.error.response ? action.error.response.data : unknownError,
        error: httpError(action),
      }
    default:
      return state
  }
}

export const convertMilesReducer = (state = {}, action) => {
  switch (action.type) {
    case Actions.miles:
      return {
        ...state,
        convertMiles: parseFloat(action.payload.convertMiles),
      }
    default:
      return state
  }
}

export const transferReducer = (state = {}, action) => {
  switch (action.type) {
    case Actions.transfer:
      return {
        transferring: false,
      }
    case Actions.transferSuccess:
      return {
        transferring: true,
        data: action.payload.data,
      }
    case Actions.transferFail:
      return {
        ...state,
        transferring: true,
        data: action.error.response ? action.error.response.data : unknownError,
        error: httpError(action),
      }
    default:
      return state
  }
}

export default combineReducers({
  login: loginReducer,
  logout: logoutReducer,
  profile: profileReducer,
  marriott: marriottReducer,
  nominate: nominateReducer,
  convertMiles: convertMilesReducer,
  transferMiles: transferReducer,
})
