import React, { Component } from 'react'
import styled from 'styled-components'
import { Redirect } from 'react-router'

import CenterColumn from '../components/signup/centerColumn'
import LeftColumn from '../components/signup/leftColumn'
import RightColumnSignup from '../components/signup/right_column/rightColumnSignup'
import RightColumnLoader from '../components/signup/right_column/rightColumnLoader'
import RightColumnMarriott from '../components/signup/right_column/rightColumnMarriott'
import RightColumnConfirm from '../components/signup/right_column/rightColumnConfirm'
import RightColumnMessages from '../components/signup/right_column/rightColumnMessages'
import { maskAccount } from '../actions'
import { trackEvent } from '../analytics/reactGAMiddlewares'

import i18next from 'i18next'

const StyledSignupFlexRow = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    padding: 0px 0px 30px;
  }
`

const StyledSignup = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // margin with promo banner
  // margin: 180px 0px 0px 0px;
  // Original margin without promo banner
  margin: 125px 0px 0px 0px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    flex-direction: row;
    padding: 30px 0px;
  }
`

class Signup extends Component {
  render() {
    const authCode = this.props.authCode.onlineAuthCode
    const profile = this.props.profile.data
    const marriott = this.props.marriott
    const nominate = this.props.nominate

    // Event Tracking:
    const category = 'Signup:'

    if (this.props.profile.error || (nominate.error && nominate.error.status > 500)) {
      const error = this.props.profile.error ? this.props.profile.error : nominate.error
      const event = {
        category: category,
        action: 'Profile | Nominate Network Error',
        label: `ErrorPage: ${this.props.profile.error ? 'Profile Network Error' : 'Nominate Network Error'}`,
        value: 0,
      }
      trackEvent(event)
      return <Redirect to={{ pathname: '/error', state: { error: error } }} />
    }
    if (profile) {
      const maskedMP = maskAccount(profile.account)

      if (profile.memberLevel < 2) {
        const event = {
          category: category,
          action: 'profile.memberLevel < 2',
          label: 'ErrorA: Member not eligible',
          value: 0,
        }
        trackEvent(event)
        return (
          <StyledSignup>
            <LeftColumn />
            <RightColumnMessages profile={profile} message="ErrorA" maskedMP={maskedMP} />
          </StyledSignup>
        )
      } else if (profile.isBlacklisted) {
        const event = {
          category: category,
          action: 'profile.isBlacklisted',
          label: 'ErrorD: Member not eligible see T&C',
          value: 0,
        }
        trackEvent(event)
        return (
          <StyledSignup>
            <LeftColumn />
            <RightColumnMessages profile={profile} message="ErrorD" maskedMP={maskedMP} />
          </StyledSignup>
        )
      } else if (profile.inRegistration) {
        const event = {
          category: category,
          action: 'profile.inRegistration',
          label: 'ErrorB: Currently verfiying registration',
          value: 0,
        }
        trackEvent(event)
        return (
          <StyledSignup>
            <LeftColumn />
            <RightColumnMessages profile={profile} message="ErrorB" maskedMP={maskedMP} />
          </StyledSignup>
        )
      } else if (profile.isRegistered) {
        const event = {
          category: category,
          action: 'profile.isRegistered',
          label: 'ErrorC: Already successfully registered',
          value: 0,
        }
        trackEvent(event)
        return (
          <StyledSignup>
            <LeftColumn />
            <RightColumnMessages profile={profile} message="ErrorC" maskedMP={maskedMP} />
          </StyledSignup>
        )
      } else if (nominate.nominating) {
        if (nominate.isNominated && nominate.data.exception == null) {
          const event = {
            category: category,
            action: `nominate.data.isNominated: ${nominate.isNominated}`,
            label: 'Success',
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnMessages profile={profile} message="Success" maskedMP={maskedMP} />
            </StyledSignup>
          )
        } else if (nominate.data.exception && (nominate.data.exception.exceptionCode === '131' || nominate.data.exception.exceptionCode === '214')) {
          const event = {
            category: category,
            action: `nominate.data.exception.exceptionCode: ${nominate.data.exception.exceptionCode}`,
            label: 'ErrorG: Technical difficulties',
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnMessages profile={profile} message="ErrorG" maskedMP={maskedMP} />
            </StyledSignup>
          )
        } else if (nominate.data.exception && nominate.data.exception.exceptionCode === '210') {
          const event = {
            category: category,
            action: `nominate.data.exception.exceptionCode: ${nominate.data.exception.exceptionCode}`,
            label: 'ErrorD: Member not eligible see T&C',
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnMessages profile={profile} message="ErrorD" maskedMP={maskedMP} />
            </StyledSignup>
          )
        } else if (nominate.data.exception && nominate.data.exception.exceptionCode === '211') {
          const event = {
            category: category,
            action: `nominate.data.exception.exceptionCode: ${nominate.data.exception.exceptionCode}`,
            label: 'ErrorC: Already successfully registered',
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnMessages profile={profile} message="ErrorC" maskedMP={maskedMP} />
            </StyledSignup>
          )
        } else if (nominate.data.exception && nominate.data.exception.exceptionCode === '212') {
          const event = {
            category: category,
            action: `nominate.data.exception.exceptionCode: ${nominate.data.exception.exceptionCode}`,
            label: 'ErrorA: Member not eligible',
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnMessages profile={profile} message="ErrorA" maskedMP={maskedMP} />
            </StyledSignup>
          )
        } else if (nominate.data.exception && nominate.data.exception.exceptionCode === '213') {
          const event = {
            category: category,
            action: `nominate.data.exception.exceptionCode: ${nominate.data.exception.exceptionCode}`,
            label: 'ErrorF: Marriott account does not match last name from United MileagePlus member profile',
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnMessages profile={profile} message="ErrorF" maskedMP={maskedMP} />
            </StyledSignup>
          )
        } else if (nominate.data.exception && nominate.data.exception.exceptionCode === '216') {
          const event = {
            category: category,
            action: `nominate.data.exception.exceptionCode: ${nominate.data.exception.exceptionCode}`,
            label: 'ErrorE: System Error - try again',
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnMessages profile={profile} message="ErrorE" maskedMP={maskedMP} />
            </StyledSignup>
          )
        } else {
          // Error out
          const event = {
            category: category,
            action: `nominate.data.exception.exceptionCode: else case`,
            label: `ErrorI: Nominate Error out`,
            value: 0,
          }
          trackEvent(event)
          return (
            <StyledSignup>
              <LeftColumn />
              <RightColumnMessages profile={profile} message="ErrorI" maskedMP={maskedMP} />
            </StyledSignup>
          )
        }
      } else if (!marriott.overrideMarriottNumber && (profile.marriottNumber || marriott.marriottNumber)) {
        let maskedMR = null
        let mr = null
        if (marriott.marriottNumber) {
          maskedMR = maskAccount(marriott.marriottNumber)
          mr = marriott
        } else {
          maskedMR = maskAccount(profile.marriottNumber)
          mr = { marriottNumber: profile.marriottNumber }
        }
        const event = {
          category: category,
          action: 'Confirm Page',
          label: 'Confirm Form',
          value: 0,
        }
        trackEvent(event)
        return (
          <StyledSignup>
            <LeftColumn />
            <RightColumnConfirm authCode={authCode} profile={profile} marriott={mr} maskedMP={maskedMP} maskedMR={maskedMR} />
          </StyledSignup>
        )
      } else {
        // We can ask for User's Marriott Number
        const event = {
          category: category,
          action: 'Marriott Page',
          label: 'Marriott Form',
          value: 0,
        }
        trackEvent(event)
        return (
          <StyledSignup>
            <LeftColumn />
            <RightColumnMarriott profile={profile} maskedMP={maskedMP} />
          </StyledSignup>
        )
      }
    }
    if (authCode) {
      return (
        <StyledSignup>
          <LeftColumn />
          <RightColumnLoader />
        </StyledSignup>
      )
    }
    return (
      <StyledSignupFlexRow>
        <StyledSignup>
          <LeftColumn />
          <RightColumnSignup />
        </StyledSignup>
        <CenterColumn />
      </StyledSignupFlexRow>
    )
  }
}

export default Signup
