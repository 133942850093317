import React, { Component } from 'react'
import styled from 'styled-components'
import { Trans, translate } from 'react-i18next'

import Container from '../../containers/Container'
import ButtonLink from '../common/buttonLink'
import { RightColumn } from '../common/rightColumn'
import { LeftColumn } from '../common/leftColumn'
import { Title } from '../common/title'
import { Paragraph } from '../common/paragraph'

import i18next from 'i18next'

const MarriottLoginContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    flex-direction: row;
  }
`

const StyledName = styled.span`
  white-space: nowrap;
`

const StyledLeftColumn = styled(LeftColumn)`
  padding-right: ${props => (/^jp/.test(i18next.language) ? '75px' : '100px')};
`

export class MarriottLogin extends Component {
  render() {
    const { t } = this.props

    return (
      <Container {...this.props}>
        <MarriottLoginContent>
          <StyledLeftColumn>
            <Title>
              <Trans i18nKey="marriottLogin.left.title">
                ..
                <StyledName>..</StyledName>
                ..
                <br />
                ..
                <br />
                ..
              </Trans>
            </Title>
            <Paragraph>
              <Trans i18nKey="marriottLogin.left.text">
                ..
                <StyledName>..</StyledName>
                ..
                <a href={t('marriottLogin.left.linkUri')}>..</a>
                ..
                <br />
                ..
                <StyledName>..</StyledName>
                ..
                <br />
                <StyledName>..</StyledName>
                ..
              </Trans>
            </Paragraph>
          </StyledLeftColumn>
          <RightColumn>
            <ButtonLink target={t('marriottLogin.right.buttonUri')} text={t('marriottLogin.right.buttonText')} />
          </RightColumn>
        </MarriottLoginContent>
      </Container>
    )
  }
}

export default translate('translation')(MarriottLogin)
