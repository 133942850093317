import React, { Component } from 'react'

import styled from 'styled-components'

import LeftColumn from './leftColumn'
import RightColumn from './rightColumn'

const StyledPoints = styled.div`
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    margin: 0px 0px 0px 0px;
    flex-direction: row;
  }
`

class Points extends Component {
  render() {
    return (
      <StyledPoints>
        <LeftColumn />
        <RightColumn />
      </StyledPoints>
    )
  }
}

export default Points
