import React, { Component } from 'react'

import Terms from '../components/terms/terms'

class TermsAndConditions extends Component {
  render() {
    return <Terms />
  }
}

export default TermsAndConditions
