import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import MarriottForm from './forms/marriottForm'

import FormContainer from '../../common/formContainer'
import Subtitle from '../../common/formSubTitle'
import Title from '../../common/formTitle'
import StyledText from '../../common/formText'

const StyledSpan = styled.span`
  font-weight: 600;
`

class RightColumnMarriott extends Component {
  render() {
    const t = this.props.t

    const firstName = this.props.profile.firstname
    const balance = this.props.profile.balance
    const memberLevel = this.props.profile.memberLevel
    const eliteLevels = this.props.elite
    const maskedMP = this.props.maskedMP

    return (
      <FormContainer id="RightColumn">
        <div>
          <Title>
            {t('miles.right.marriott.title')} {firstName}
          </Title>
          <Subtitle>
            <StyledSpan>
              <Trans i18nKey={eliteLevels[memberLevel]}>
                Premier
                <sup>®</sup> 1K
                <sup>®</sup>
              </Trans>
            </StyledSpan>
            <br />
            <StyledSpan>
              <Trans i18nKey="miles.right.marriott.mp_number_title">
                MileagePlus
                <sup>®</sup> number:
              </Trans>
            </StyledSpan>
            &nbsp;
            {maskedMP}
            <br />
            <StyledSpan>{t('miles.right.marriott.balance')}</StyledSpan> {balance}
            <br />
          </Subtitle>
          <StyledText>{t('miles.right.marriott.text')}</StyledText>

          {/* FORM */}
          <MarriottForm />
        </div>
      </FormContainer>
    )
  }
}

export default translate('translation')(RightColumnMarriott)
