import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

const StyledDisclaimer = styled.div`
  margin-top: 24px;
`

const Clause = styled.div`
  & a {
    color: #006080;
  }
`

class Disclaimer extends Component {
  render() {
    const t = this.props.t

    return (
      <StyledDisclaimer id="Disclaimer">
        <Clause>
          <Trans i18nKey="signup.right.disclaimerA">
            Marriott Bonvoy Titanium Elite or Ambassador Elite member?
            <a
              href={t('signup.uri.right.marriott_rewards_plus')} 
              target="_blank"
              rel="noopener noreferrer"
            >
              Register through Marriott Bonvoy
            </a>
            .
          </Trans>
          <br />
          <br />
          <div>
            <span>{t('signup.right.mileagePlusEnrollText')} </span>
            <a
              href={t('signup.right.mileagePlusEnrollLinkUri')} 
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('signup.right.mileagePlusEnrollLinkText')}
            </a>
          </div>
        </Clause>
      </StyledDisclaimer>
    )
  }
}

export default translate('translation')(Disclaimer)
