import React, { Component } from 'react'
import styled from 'styled-components'
import { Trans, translate } from 'react-i18next'

import Container from '../../containers/Container'

import deskopBannerLogoImgUrl from '../../images/desktopBannerLogoImg.png'

import deskopBannerImgUrl from '../../images/promo_MVW_AboundLogo_desktop.png'
import mobileBannerImgUrl from '../../images/promo_MVW_AboundLogo_mobile.png'

import ButtonLink from '../common/buttonLink'

import i18next from 'i18next'

const StyledPromoContainer = styled.div`
  display: ${props => (/^en/.test(i18next.language) ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: normal;
  }
`
const StyledPromoTextContainer = styled.div`
  max-width: 468px;
  background-color: #0b2340;
  color: #fff;
  padding: 10px 20px 20px 20px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    flex-direction: row;
    max-width: 511px;
    width: 511px;
  }
`

const StyledPromoImgContainer = styled.div`
  max-width:468px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    width: 399px;
  }
`
const BannerMobileImage = styled.img`
  max-width: 100%;
  height: auto;

  display: block;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    display: none;
  }
`

const StyledHeader = styled.h3`
  font-size: ${props => (/^en/.test(i18next.language) ? '22px' : (/^jp/.test(i18next.language) ? '16px' : '20px'))};
  font-weight: 600;
  line-height: ${props => (/^en/.test(i18next.language) ? '22px' : (/^jp/.test(i18next.language) ? '16px' : '20px'))};
  margin: ${props => (/^(jp|pt)/.test(i18next.language) ? '5px 0 10px 0' : '10px 0 10px 0')}; 

  @media (max-width: ${props => props.theme.minWidth.giga}) {
    font-size: 22px;
    line-height: 22px;
    margin: 10px 0 10px 0;
  }
`

const StyledText = styled.div`
  color: #fff
  font-size: 15px;
  line-height: 25px;
`

const StyledName = styled.span`
  white-space: nowrap;
`

const BannerDesktopLogoImage = styled.img`
  max-width: 100%;
  height: auto;

  display: none;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    display: block;
  }
`

const BannerDesktopImage = styled.img`
  max-width: 100%;
  height: auto;

  display: none;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    display: block;
  }
`

const PromoButton = styled.div`
  margin-top: 20px;

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    position: absolute;
    bottom: ${props => (/^(jp|pt)/.test(i18next.language) ? '35px' : '40px')}; 
  }
`

export class PromotioTwo extends Component {
  render() {
    const { t } = this.props

    return (
      <Container style={{}}>

        <StyledPromoContainer id="promotionTwo">
          <StyledPromoImgContainer>
            <BannerDesktopImage src={deskopBannerImgUrl} />
            <BannerMobileImage src={mobileBannerImgUrl} />
          </StyledPromoImgContainer>
          <StyledPromoTextContainer>
            <StyledHeader>
              <Trans i18nKey="promotionTwo.title">
              ..
              <StyledName>..</StyledName>
              ..
            </Trans>
            </StyledHeader>
            <StyledText>
              <Trans i18nKey="promotionTwo.text">
                ..
                <sup>..</sup>
                ..
                <br />
                ..
              </Trans>
            </StyledText>
            <PromoButton>
              <ButtonLink target={t('promotionTwo.uri')} text={t('promotionTwo.buttonText')} openInNew={true} targetBlank={true}/>
            </PromoButton>
          </StyledPromoTextContainer>
        </StyledPromoContainer>

      </Container>
    )
  }
}

export default translate('translation')(PromotioTwo)
