import { applyMiddleware, createStore, combineReducers, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { createLogger } from 'redux-logger'
import axios from 'axios'
import axiosMiddleware from 'redux-axios-middleware'
import thunk from 'redux-thunk'
import { googleAnalytics } from './analytics/reactGAMiddlewares'

import Store from './reducer'

const rootReducer = combineReducers({
  Store,
  form: formReducer, // <---- Mounted at 'form'
})

const composeEnhancers = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
const logger = createLogger()

const axiosClient = axios.create({
  //all axios can be used, shown in axios documentation
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
  // todo: add common auth parameters/headers here
})

const middleware = [axiosMiddleware(axiosClient), thunk, googleAnalytics, logger]

const store = createStore(
  rootReducer,
  /* preloadedState, */
  composeEnhancers(applyMiddleware(...middleware))
)

export default store
