import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import FormContainer from '../../common/formContainer'
import Subtitle from '../../common/formSubTitle'
import Title from '../../common/formTitle'
import StyledText from '../../common/formText'

const Message = styled.div``

const StyledSpan = styled.span`
  font-weight: 600;
`

const Anchor = styled.a`
  color: #002244;
  text-decoration: underline;

  &:hover,
  :focus {
    color: #002244;
    text-decoration: none;
  }
`

const StyledLink = styled(Link)`
  color: #002244;
  text-decoration: underline;

  &:hover,
  :focus {
    color: #002244;
    text-decoration: none;
  }
`

class RightColumnMessages extends Component {
  renderErrorA(t, message) {
    if (message === 'ErrorA') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorA.messageA')}</StyledText>
          <StyledText>
            <Trans i18nKey="miles.right.messages.errorA.messageB">
              Premier
              <sup>®</sup> Silver, Premier Gold, Premier Platinum, Premier 1K
              <sup>®</sup> members are eligible to convert MileagePlus award miles into Marriott Rewards points.
            </Trans>
          </StyledText>
          <StyledText>
            <Trans i18nKey="miles.right.messages.errorA.messageC">
              If you're a Marriott Bonvoy Platinum Elite member requesting complimentary MileagePlus Premier Silver status,
              <Anchor href={t('miles.uri.right.marriott_rewards_plus')}>register through Marriott Bonvoy</Anchor>.
            </Trans>
          </StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorB(t, message) {
    if (message === 'ErrorB') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorB')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorC(t, message) {
    if (message === 'ErrorC') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorC')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorD(t, message) {
    if (message === 'ErrorD') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorD.messageA')}</StyledText>
          <StyledText>{t('miles.right.messages.errorD.messageB')}</StyledText>
          <StyledText>{t('miles.right.messages.errorD.messageC')}</StyledText>
          <StyledText>{t('miles.right.messages.errorD.messageD')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorE(t, message) {
    if (message === 'ErrorE') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorE')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorF(t, message) {
    if (message === 'ErrorF') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorF')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorG(t, message) {
    if (message === 'ErrorG') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorG')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorH(t, message) {
    if (message === 'ErrorH') {
      return (
        <Message>
          <StyledText>
            <Trans i18nKey="miles.right.messages.errorH">
              Our records indicate that you are not currently eligible for this offer. Please see the
              <StyledLink to="terms">Terms and Conditions</StyledLink> for eligibility requirements.
            </Trans>
          </StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorI(t, message) {
    if (message === 'ErrorI') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorI')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorJ(t, message) {
    if (message === 'ErrorJ') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorJ')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorK(t, message) {
    if (message === 'ErrorK') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorK')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorL(t, message) {
    if (message === 'ErrorL') {
      return (
        <Message>
          <StyledText>{t('miles.right.messages.errorL')}</StyledText>
        </Message>
      )
    }
    return null
  }

  render() {
    const t = this.props.t

    const firstName = this.props.profile.firstname
    const balance = this.props.profile.balance
    const memberLevel = this.props.profile.memberLevel || 0
    const eliteLevels = this.props.elite
    const message = this.props.message
    const maskedMP = this.props.maskedMP

    return (
      <FormContainer id="RightColumn">
        <div>
          <Title>
            {t('miles.right.marriott.title')} {firstName}
          </Title>
          <Subtitle>
            <StyledSpan>
              <Trans i18nKey={eliteLevels[memberLevel]}>
                ..
                <sup>..</sup>
                ..
                <sup>..</sup>
              </Trans>
            </StyledSpan>
            <br />
            <StyledSpan>
              <Trans i18nKey="miles.right.marriott.mp_number_title">
                ..
                <sup>..</sup>
                ..
              </Trans>
            </StyledSpan>
            &nbsp;
            {maskedMP}
            <br />
            <StyledSpan>{t('miles.right.marriott.balance')}</StyledSpan> {balance}
          </Subtitle>

          {this.renderErrorA(t, message)}
          {this.renderErrorB(t, message)}
          {this.renderErrorC(t, message)}
          {this.renderErrorD(t, message)}
          {this.renderErrorE(t, message)}
          {this.renderErrorF(t, message)}
          {this.renderErrorG(t, message)}
          {this.renderErrorH(t, message)}
          {this.renderErrorI(t, message)}
          {this.renderErrorJ(t, message)}
          {this.renderErrorK(t, message)}
          {this.renderErrorL(t, message)}
        </div>
      </FormContainer>
    )
  }
}

export default translate('translation')(RightColumnMessages)
