import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import Disclaimer from './disclaimer'
import MarriottForm from './forms/marriottForm'
import FormContainer from '../../common/formContainer'
import Subtitle from '../../common/formSubTitle'
import Title from '../../common/formTitle'
import StyledText from '../../common/formText'

const StyledSpan = styled.span`
  font-weight: 500;
`

class RightColumnMarriott extends Component {
  render() {
    const t = this.props.t

    const firstName = this.props.profile.firstname
    const maskedMP = this.props.maskedMP

    return (
      <FormContainer id="RightColumn">
        <div>
          <Title>
            {t('signup.right.marriott.title')} {firstName},
          </Title>
          <Subtitle>
            <StyledSpan>
              <Trans i18nKey="signup.right.marriott.subtitle">
                MileagePlus
                <sup>®</sup> number:
              </Trans>
            </StyledSpan>
            &nbsp;
            {maskedMP}
          </Subtitle>
          <StyledText>
            <Trans i18nKey="signup.right.marriott.text">
              Congratulations. You're eligible to receive complimentary Marriott Bonvoy
              <sup>TM</sup> Gold Elite status.
            </Trans>
          </StyledText>

          {/* FORM */}
          <MarriottForm />
        </div>
        <Disclaimer />
      </FormContainer>
    )
  }
}

export default translate('translation')(RightColumnMarriott)
