import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import HelmetHead from './containers/HelmetHead'

import Home from './containers/Home'
import Faqs from './containers/Faqs'
import Terms from './containers/Terms'
import ErrorPage from './containers/Error'

import withTracker from './analytics/withTracker'

class ApplicationRouter extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <HelmetHead />
          <Switch>
            <Route exact path="/" component={withTracker(Home)} />
            <Route exact path="/home" component={withTracker(Home)} />
            <Route exact path="/faqs" component={withTracker(Faqs)} />
            {/* <Route exact path="/maintenance" component={withTracker(Maintenance)} /> */}
            <Route exact path="/miles" component={withTracker(Home)} />
            <Route exact path="/notmr" component={withTracker(Home)} />
            <Route exact path="/points" component={withTracker(Home)} />
            <Route exact path="/rewards" component={withTracker(Home)} />
            <Route exact path="/signup" component={withTracker(Home)} />
            <Route exact path="/terms" component={withTracker(Terms)} />

            <Route component={withTracker(ErrorPage)} />
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}

export default ApplicationRouter
