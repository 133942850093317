import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { translate } from 'react-i18next'

import { convertMiles } from '../../../../actions'
import renderField from '../../../form/renderField'
import { maxValue_100000, minValue_500, required } from '../../../form/validators'

import Button from '../../../common/button'
import Form from '../../../common/form'
import FormText from '../../../common/formText'

class ConvertForm extends Component {
  constructor(props) {
    super(props)

    this.state = { points: 0 }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.setState({ points: e.target.value })
  }

  validateMilesBalance = value => {
    return value > this.props.balance ? `validators.maxValue_balance` : undefined
  }

  renderForm() {
    const t = this.props.t
    const { handleSubmit, submitting } = this.props

    const points = this.state.points

    const validatorMiles = [required, minValue_500, this.validateMilesBalance, maxValue_100000]

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name="miles"
          label={t('miles.right.convert.form.miles')}
          component={renderField}
          type="number"
          onChange={this.handleChange}
          validate={validatorMiles}
        >
          <FormText>
            {parseFloat(points) || 0}
            &nbsp;
            {t('miles.right.convert.form.counter')}
          </FormText>
        </Field>

        <Button type="submit" disabled={submitting} alt="Sign In">
          {t('miles.right.convert.form.button')}
        </Button>
      </Form>
    )
  }

  render() {
    return this.renderForm()
  }
}

// Decorate the form component with redux-form
const ConvertReduxForm = reduxForm({
  form: 'convertForm', // a unique name for this form
})(ConvertForm)

// Decorate the form component with connect
const mapStateToProps = (state, ownProps) => {
  return {
    //...state,
  } // when in doubt pass nothing
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (formData, dispatch, props) => {
      dispatch(props.startSubmit)
      dispatch(convertMiles(formData.miles))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translation')(ConvertReduxForm))
