import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'
import i18next from 'i18next'
import PanelGroup from 'react-bootstrap/lib/PanelGroup'
import Panel from 'react-bootstrap/lib/Panel'
import styled from 'styled-components'

import Header from '../header/header'
import Footer from '../footer/footer'

import faq_arrow_down from '../../images/FAQ_arrow_down.png'
import faq_arrow_up from '../../images/FAQ_arrow_up.png'
import background from '../../images/FAQ_background.jpg'

const FaqContainer = styled.div`
  display: inline-block;
  background: url(${background});
  background-size: cover;
  width: 100%;
`

const StyledFaqs = styled.div`
  font-family: ${props => (/^jp/.test(i18next.language) ? props.theme.font.jp : props.theme.font.default)};
  background-color: white;

  max-width: 900px;
  min-height: 800px;

  transition-property: width, margin;
  transition-duration: 0.5s;

  margin: 75px auto 0px;
  padding: 0px 0px 20px 0px;

  @media (min-width: 900px) {
    margin: 200px auto 85px;
  }
`

const NoteTitle = styled.div`
  padding: 0px 0px 5px 44px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
`

const Note = styled.div`
  color: #000000;
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;

  padding: 0px 42px 15px 44px;
`

const Summary = styled.div`
  color: #000000;
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;

  padding: 10px 42px 30px 44px;

  & > a {
    color: #000000;
    text-decoration: underline;

    &:hover {
      color: #002244;
      text-decoration: none;
    }
  }
`

export const FaqTitle = styled.div`
  color: #000000;
  font-size: 36px;
  line-height: 46px;
  font-weight: 300;
  padding: 27px 0px 33px 44px;
`

const StyledPanelGroup = styled(PanelGroup)`
  width: 88%;
  margin: 0px auto;

  .panel-default > .panel-heading {
    background: #ffffff;
  }

  .panel {
    border: none;
    box-shadow: none;
  }

  .panel-collapse > .panel-body {
    /* border-top: 1px solid #ddd; */
    border: none !important;
  }

  .panel-default > .panel-heading:hover,
  .panel-heading:focus {
    background: #e6e6e6;
  }
`

const StyledPanel = styled(Panel)`
  border-top: 1px solid black !important;
  border-radius: 0px !important;
  padding-top: 5px;

  &:last-child {
    border-bottom: 1px solid black !important;
    border-radius: 0px !important;
    padding-bottom: 5px;
  }
`

const StyledPanelHeading = styled(Panel.Heading)`
  padding: 21px 21px 21px 21px;

  & > .panel-title > a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  & .panel-title > a.collapsed:after {
    content: url(${faq_arrow_down});
  }

  & .panel-title > a:after {
    content: url(${faq_arrow_up});
    float: right;
  }
`

const StyledQuestion = styled(Panel.Title)`
  color: #000000;
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;

  & > a:hover,
  a:focus {
    text-decoration: none;
  }
`

const StyledAnswer = styled.div`
  color: #000000;
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  padding: 5px 15px 5px 15px;

  & > ul {
    list-style-type: circle;
  }

  & > a {
    color: #000000;
    text-decoration: underline;

    &:hover {
      color: #002244;
      text-decoration: none;
    }
  }
`
const Copyright = styled.div`
  color: #000000;
  font-size: 11px;
  line-height: 19px;
  font-weight: 300;
  padding: 10px 42px 0px 44px;
`

export class FrequentlyAskedQuestions extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const t = this.props.t

    return (
      <FaqContainer>
        <Header />
        <StyledFaqs id="Faqs">
          <FaqTitle>{t('faqs.title')}</FaqTitle>
          <NoteTitle>{t('faqs.note_title')}</NoteTitle>
          <Note>
            <Trans i18nKey="faqs.note">
              Marriott offers travelers a choice of three frequent travel programs: the Marriott Rewards
              <sup>®</sup> Program, The Ritz-Carlton Rewards Program and the Starwood Preferred Guest
              <sup>®</sup> Program. Effective August 2018, Marriott Rewards, The Ritz-Carlton Rewards, and the SPG will keep their
              separate names for the time being but will function as one program operating under the same terms and conditions.
            </Trans>
          </Note>
          <Summary>
            <Trans i18nKey="faqs.summary">
              The following answers summarize the RewardsPlus program and are qualified by the
              <a
                href={t('faqs.answers.uri.summary.marriott_terms')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                full RewardsPlus terms and conditions
              </a>
              which can be found
              <a
                href={t('faqs.answers.uri.summary.marriott_terms')} 
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </Trans>
          </Summary>

          <StyledPanelGroup accordion id="Faq-Accordion">
            <StyledPanel id="q1" eventKey="1">
              <StyledPanelHeading>
                <StyledQuestion toggle>1.&nbsp;&nbsp; {t('faqs.questions.one')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.one.intro">
                    “RewardsPlus” is a partnership with Marriott Bonvoy
                    <sup>TM</sup> and United MileagePlus
                    <sup>®</sup>, offering new and enhanced benefits.
                  </Trans>
                </StyledAnswer>

                <StyledAnswer>{t('faqs.answers.one.listA.title')}</StyledAnswer>
                <StyledAnswer>
                  <ul>
                    <li>
                      <Trans i18nKey="faqs.answers.one.intro">
                        Complimentary Gold Elite status with Marriott Bonvoy (open to MileagePlus Premier
                        <sup>®</sup> Gold members and above, registration required)
                      </Trans>
                    </li>
                    <li>{t('faqs.answers.one.listA.item_b')}</li>
                    <li>{t('faqs.answers.one.listA.item_c')}</li>
                  </ul>
                </StyledAnswer>

                <StyledAnswer>{t('faqs.answers.one.listB.title')}</StyledAnswer>
                <StyledAnswer>
                  <ul>
                    <li>{t('faqs.answers.one.listB.item_a')}</li>
                    <li>{t('faqs.answers.one.listB.item_b')}</li>
                  </ul>
                </StyledAnswer>

                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.one.outro_a">
                    Eligible members can register for complimentary MileagePlus Silver status at
                    <a
                      href={t('faqs.answers.uri.one.marriott_rewards_plus')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >  
                      getrewardsplus.com
                    </a>
                    .
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q2" eventKey="2">
              <StyledPanelHeading>
                <StyledQuestion toggle>2.&nbsp;&nbsp; {t('faqs.questions.two')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>{t('faqs.answers.two')}</StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q3" eventKey="3">
              <StyledPanelHeading>
                <StyledQuestion toggle>3.&nbsp;&nbsp; {t('faqs.questions.three')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>{t('faqs.answers.three.intro')}</StyledAnswer>
                <StyledAnswer>{t('faqs.answers.three.outro')}</StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q4" eventKey="4">
              <StyledPanelHeading>
                <StyledQuestion toggle>4.&nbsp;&nbsp; {t('faqs.questions.four')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.four.intro">
                    The benefits of Gold Elite status
                    <sup>1</sup> include:
                  </Trans>
                </StyledAnswer>
                <StyledAnswer>
                  <ul>
                    <li>
                      <Trans i18nKey="faqs.answers.four.listA.item_a">
                        Complimentary enhanced Wi-Fi
                        <sup>1</sup>
                      </Trans>
                    </li>
                    <li>{t('faqs.answers.four.listA.item_b')}</li>
                    <li>{t('faqs.answers.four.listA.item_c')}</li>
                    <li>{t('faqs.answers.four.listA.item_d')}</li>
                    <li>{t('faqs.answers.four.listA.item_e')}</li>
                    <li>{t('faqs.answers.four.listA.item_f')}</li>
                    <li>{t('faqs.answers.four.listA.item_g')}</li>
                    <li>{t('faqs.answers.four.listA.item_h')}</li>
                  </ul>
                </StyledAnswer>

                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.four.outro">
                    <sup>1</sup>
                    Benefits vary by hotel. Room upgrades and other benefits are offered when/where available. For more information on
                    on the full benefits of Gold Elite status, please visit
                    <a
                      href={t('faqs.answers.uri.four.member-benefits')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    > 
                      https://www.marriott.com/loyalty/member-benefits/gold.mi
                    </a>
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q5" eventKey="5">
              <StyledPanelHeading>
                <StyledQuestion toggle>5.&nbsp;&nbsp; {t('faqs.questions.five')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.five.intro">
                    The benefits of MileagePlus Premier Silver status
                    <sup>2</sup> include:
                  </Trans>
                </StyledAnswer>
                <StyledAnswer>
                  <ul>
                    <li>
                      <Trans i18nKey="faqs.answers.five.listA.item_a">
                        Complimentary access to Economy Plus
                        <sup>®</sup> at check-in (when available)
                      </Trans>
                    </li>
                    <li>{t('faqs.answers.five.listA.item_b')}</li>
                    <li>
                      <Trans i18nKey="faqs.answers.five.listA.item_c">
                        Premier Access
                        <sup>®</sup> travel services, which include designated ticket counter check-in lines, priority security lines,
                        priority boarding and priority baggage handling (where available)
                      </Trans>
                    </li>
                    <li>{t('faqs.answers.five.listA.item_d')}</li>
                    <li>{t('faqs.answers.five.listA.item_e')}</li>
                    <li>{t('faqs.answers.five.listA.item_f')}</li>
                  </ul>
                </StyledAnswer>

                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.five.outro">
                    <sup>2</sup>
                    For more information on the full benefits of MileagePlus Premier Silver status, please visit
                    <a
                      href={t('faqs.answers.uri.five.premier')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      united.com/premier
                    </a>
                    .
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q6" eventKey="6">
              <StyledPanelHeading>
                <StyledQuestion toggle>6.&nbsp;&nbsp; {t('faqs.questions.six')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.six">
                    Yes. Members may join MileagePlus at
                    <a
                      href={t('faqs.answers.uri.six.mileageplus')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.mileageplus.com
                    </a>
                    or Marriott Rewards, The Ritz-Carlton Rewards and SPG at
                    <a
                      href={t('faqs.answers.uri.six.marriott_create_account')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.marriott.com/rewards/createAccount/createAccountPage1.mi
                    </a>
                    ,
                    <a
                      href={t('faqs.answers.uri.six.ritz_create_account')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.marriott.com/ritz/rewards/createAccount/createAccountPage1.mi
                    </a>
                    or
                    <a
                      href={t('faqs.answers.uri.six.spg_create_account')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.starwoodhotels.com/preferredguest/account/enroll
                    </a>
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q7" eventKey="7">
              <StyledPanelHeading>
                <StyledQuestion toggle>7.&nbsp;&nbsp; {t('faqs.questions.seven')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>{t('faqs.answers.seven')}</StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q8" eventKey="8">
              <StyledPanelHeading>
                <StyledQuestion toggle>8.&nbsp;&nbsp; {t('faqs.questions.eight')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.eight.item_a">
                    ...
                    <a
                      href={t('faqs.answers.uri.eight.rewards_plus')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ...
                    </a>
                    ...
                  </Trans>
                </StyledAnswer>
                <StyledAnswer>{t('faqs.answers.eight.item_b')}</StyledAnswer>
                <StyledAnswer>{t('faqs.answers.eight.item_c')}</StyledAnswer>
                <StyledAnswer>{t('faqs.answers.eight.item_d')}</StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q9" eventKey="9">
              <StyledPanelHeading>
                <StyledQuestion toggle>9.&nbsp;&nbsp; {t('faqs.questions.nine')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>{t('faqs.answers.nine')}</StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q10" eventKey="10">
              <StyledPanelHeading>
                <StyledQuestion toggle>10.&nbsp;&nbsp; {t('faqs.questions.ten')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>{t('faqs.answers.ten')}</StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q11" eventKey="11">
              <StyledPanelHeading>
                <StyledQuestion toggle>11.&nbsp;&nbsp; {t('faqs.questions.eleven')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.eleven">
                    Yes, but only by reaching all published qualification requirements. For example, to reach Premier Gold, a member
                    must earn 50,000 Premier qualifying miles (or 60 Premier qualifying segments) and $5,000 in Premier qualifying
                    dollars with at least four paid flights operated by United, United Express or Copa during a calendar year. For
                    full qualification details, please visit
                    <a
                      href={t('faqs.answers.uri.eleven.premier')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.united.com/premier
                    </a>
                    .
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q12" eventKey="12">
              <StyledPanelHeading>
                <StyledQuestion toggle>12.&nbsp;&nbsp; {t('faqs.questions.twelve')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.twelve">
                    Yes, but only by reaching the published requirements. For example, to reach Platinum Premier Elite, a member must
                    stay 75 nights. For more information, please visit
                    <a
                      href={t('faqs.answers.uri.twelve.member-benefits')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.marriott.com/loyalty/member-benefits/platinumpremier.mi
                    </a>
                    .
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q13" eventKey="13">
              <StyledPanelHeading>
                <StyledQuestion toggle>13.&nbsp;&nbsp; {t('faqs.questions.thirteen')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>{t('faqs.answers.thirteen')}</StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q14" eventKey="14">
              <StyledPanelHeading>
                <StyledQuestion toggle>14.&nbsp;&nbsp; {t('faqs.questions.fourteen')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>{t('faqs.answers.fourteen')}</StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q15" eventKey="15">
              <StyledPanelHeading>
                <StyledQuestion toggle>
                  15.&nbsp;&nbsp;
                  <Trans i18nKey="faqs.questions.fifteen">
                    If I registered and was granted complimentary Gold Elite status through RewardsPlus and have re-qualified for
                    MileagePlus Premier Gold
                    <sup>®</sup> status or higher for the following year, will I need to also re-register my request for complimentary
                    Gold Elite status for the following year?
                  </Trans>
                </StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.fifteen">
                    No. Members who have already registered for and received complimentary Gold Elite status through RewardsPlus in a
                    calendar year and who qualify for MileagePlus Premier Gold
                    <sup>®</sup> status or higher for the following calendar year will have their previous registration with
                    RewardsPlus automatically renewed and will receive complimentary Gold Elite status for the following calendar
                    year.
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q16" eventKey="16">
              <StyledPanelHeading>
                <StyledQuestion toggle>16.&nbsp;&nbsp; {t('faqs.questions.sixteen')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.sixteen">
                    No. Eligible members who wish to enjoy the benefits of MileagePlus Premier Silver status must register and be
                    confirmed for the RewardsPlus program at
                    <a
                      href={t('faqs.answers.uri.sixteen.marriott_rewards_plus')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      marriottrewards.com/rewardsplus
                    </a>
                    to obtain their MileagePlus Premier Silver status. To enjoy Premier Silver benefits, Premier Silver members must
                    enter their MileagePlus number when booking flights with United.
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q17" eventKey="17">
              <StyledPanelHeading>
                <StyledQuestion toggle>17.&nbsp;&nbsp; {t('faqs.questions.seventeen')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.seventeen">
                    No. Eligible MileagePlus members who wish to enjoy the benefits of Gold Elite status must register for the
                    RewardsPlus program at
                    <a href={t('faqs.answers.uri.seventeen.rewards_plus')} target="_blank" rel="noopener noreferrer">
                      united.com/rewardsplus
                    </a>
                    to obtain their Gold Elite status. To enjoy Gold Elite benefits, Gold Elite members must enter their number when
                    making reservations with Marriott.
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q18" eventKey="18">
              <StyledPanelHeading>
                <StyledQuestion toggle>18.&nbsp;&nbsp; {t('faqs.questions.eighteen')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.eighteen">
                    Marriott features the largest portfolio of award-winning hotel brands in the industry with over 6,900 hotels. The
                    brands are The Ritz-Carlton
                    <sup>®</sup>, JW Marriott
                    <sup>®</sup>, EDITION
                    <sup>®</sup>, Autograph Collection
                    <sup>®</sup>, Gaylord Hotels
                    <sup>®</sup>, Renaissance
                    <sup>®</sup> Hotels, AC Hotels by Marriott
                    <sup>®</sup>, Marriott Hotels
                    <sup>®</sup> (includes Marriott Convention Centers), Courtyard by Marriott
                    <sup>®</sup>, SpringHill Suites by Marriott
                    <sup>®</sup>, Residence Inn by Marriott
                    <sup>®</sup>, TownePlace Suites by Marriott
                    <sup>®</sup>, Fairfield by Marriott
                    <sup>®</sup>, Marriott Vacation Club
                    <sup>®</sup>, MOXY
                    <sup>®</sup> Hotels, Protea Hotels by Marriott
                    <sup>®</sup>, Delta Hotels by Marriott
                    <sup>™</sup>, Aloft
                    <sup>®</sup>, Element by Westin
                    <sup>®</sup>, Four Points
                    <sup>®</sup> by Sheraton, Le Meridien
                    <sup>®</sup>, The Luxury Collection
                    <sup>®</sup>, Sheraton
                    <sup>®</sup>, St. Regis
                    <sup>®</sup>, Tribute Portfolio
                    <sup>®</sup>, W<sup>®</sup> Hotels, Westin
                    <sup>®</sup>, Design Hotels
                    <sup>™</sup>, and Marriott Executive Apartments
                    <sup>®</sup>.
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q19" eventKey="19">
              <StyledPanelHeading>
                <StyledQuestion toggle>19.&nbsp;&nbsp; {t('faqs.questions.nineteen')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.nineteen">
                    Yes. MileagePlus values all of its hotel partners and will continue to offer the option to earn miles for hotel
                    stays or to transfer hotel points into MileagePlus miles. Learn more at
                    <a
                      href={t('faqs.answers.uri.nineteen.hotel_partners')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.marriott.com/loyalty/member-benefits/gold.mi
                    </a>
                    .
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>

            <StyledPanel id="q20" eventKey="20">
              <StyledPanelHeading>
                <StyledQuestion toggle>20.&nbsp;&nbsp; {t('faqs.questions.twenty')}</StyledQuestion>
              </StyledPanelHeading>

              <Panel.Body collapsible>
                <StyledAnswer>
                  <ul>
                    <li>{t('faqs.answers.twenty.item_a')}</li>
                    <li>{t('faqs.answers.twenty.item_c')}</li>
                  </ul>
                </StyledAnswer>
                <StyledAnswer>
                  <Trans i18nKey="faqs.answers.twenty.outro">
                    *Benefits vary by brand. For more information on the full benefits of Gold Elite status, please visit
                    <a
                      href={t('faqs.answers.uri.twenty.member-benefits')} 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.marriott.com/loyalty/member-benefits/gold.mi
                    </a>
                    .
                  </Trans>
                </StyledAnswer>
              </Panel.Body>
            </StyledPanel>
          </StyledPanelGroup>
          <Copyright>{t('faqs.copyright')}</Copyright>
          
        </StyledFaqs>
        <Footer />
      </FaqContainer>
    )
  }
}

//export const FrequentlyAskedQuestions;
export default translate('translation')(FrequentlyAskedQuestions)
