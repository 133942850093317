import 'babel-polyfill' // IE 11 Fixes

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import GoogleAnalytics from 'react-ga4'
import { I18n } from 'react-i18next'
import { injectGlobal, ThemeProvider } from 'styled-components'

// Bootstrap Theme
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-theme.css'

import ApplicationRouter from './Router'
import store from './store'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'

import './i18n'

// Global styles
// eslint-disable-next-line
injectGlobal`
   html {
      height: 100%;
      width: 100%;
   }
   body {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      font-family: sans-serif;
      color: #0C2340;
   }
   *{
      box-sizing: border-box;
      margin: 0;
      padding: 0;
   }
`

const theme = {
  colors: {
    success: {
      greenOnYellow: '#035C01',
      greenOnBlue: '#0B4409',
    },
    error: {
      redOnYellow: '#A00903',
      redOnBlue: '#790704',
    },
  },
  font: {
    default: "'Open Sans', sans-serif",
    jp: "'Noto Sans', 'Noto Sans JP', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'ＭＳ ゴシック', 'MS Gothic', sans-serif",
  },
  minWidth: {
    nano: '450px',
    micro: '800px',
    giga: '1024px',
  },
  desktop: {
    contentWidth: '900px',
  },
}

GoogleAnalytics.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)

ReactDOM.render(
  <Provider store={store}>
    <I18n ns="translation">
      {(t, { i18n }) => (
        <ThemeProvider theme={theme}>
          <ApplicationRouter />
        </ThemeProvider>
      )}
    </I18n>
  </Provider>,
  document.getElementById('root')
)

// registerServiceWorker()
unregisterServiceWorker()
