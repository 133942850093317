import React, { Component } from 'react'
import Container from './Container'

import PointsToMiles from '../components/points/points'

const captionMargin = '684px 0px 24px 0px;'

class Points extends Component {
  render() {
    return (
      <Container captionMargin={captionMargin} {...this.props}>
        <PointsToMiles />
      </Container>
    )
  }
}

export default Points
