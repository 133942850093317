import React, { PureComponent } from 'react'
import { translate } from 'react-i18next'
import ControlLabel from 'react-bootstrap/lib/ControlLabel'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import FormControl from 'react-bootstrap/lib/FormControl'
import HelpBlock from 'react-bootstrap/lib/HelpBlock'

import styled from 'styled-components'

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 0px;
`

const StyledControlLabel = styled(ControlLabel)`
  color: #002244;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;

  .has-success > & {
    color: ${props =>
      props.formtype === 'Signup' ? props.theme.colors.success.greenOnYellow : props.theme.colors.success.greenOnBlue};
  }
  .has-error > & {
    color: ${props => (props.formtype === 'Signup' ? props.theme.colors.error.redOnYellow : props.theme.colors.error.redOnBlue)};
  }
`

const StyledFormControl = styled(FormControl)`
  border-radius: 0px;
  margin: 0px 42px 0px 0px;
  padding-right: 5px !important;

  &:focus {
    background: #e7e7e7;
  }
`

const StyledHelpBlock = styled(HelpBlock)`
  margin: 5px 0px 0px 0px;

  .has-success > & {
    color: ${props =>
      props.formtype === 'Signup' ? props.theme.colors.success.greenOnYellow : props.theme.colors.success.greenOnBlue};
  }
  .has-error > & {
    color: ${props => (props.formtype === 'Signup' ? props.theme.colors.error.redOnYellow : props.theme.colors.error.redOnBlue)};
  }
`

const StyledCheckboxLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 25px 0px 12px 0px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`

const StyledCheckboxInput = styled.input``

const StyledCheckboxSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;

  &:hover,
  :focus,
  :active {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  label:hover input ~ & {
    background-color: #ccc;
  }

  label input:checked ~ & {
    background-color: #fff;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  label input:checked ~ &:after {
    display: block;
  }

  label &:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid black;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`

class RenderField extends PureComponent {
  render() {
    const {
      input,
      label,
      type,
      ph,
      formtype,
      meta: { touched, valid, error, warning },
      children,
      autoComplete,
    } = this.props
    const t = this.props.t

    let validateState = null
    if (touched && valid) {
      validateState = 'success'
    }
    if (touched && warning) {
      validateState = 'warning'
    }
    if (touched && error) {
      validateState = 'error'
    }
    if (type === 'checkbox') {
      return (
        <StyledCheckboxLabel>
          <StyledCheckboxInput type={type} {...input} required />
          <StyledCheckboxSpan />
          {children}
        </StyledCheckboxLabel>
      )
    }
    return (
      <div>
        <StyledFormGroup controlId={label} validationState={validateState}>
          <StyledControlLabel formtype={formtype}>{label}</StyledControlLabel>
          <StyledFormControl {...input} placeholder={ph} type={type} autoComplete={autoComplete} />
          <FormControl.Feedback />
          <StyledHelpBlock formtype={formtype}>{touched && error ? t(error) : warning}</StyledHelpBlock>
        </StyledFormGroup>
        {children}
      </div>
    )
  }
}

export default translate('translation')(RenderField)
