import React, { Component } from 'react'
import styled from 'styled-components'

import Container from './Container'
import Header from '../components/header/header'
import ErrorPage from '../components/errors/errorPage'
import Footer from '../components/footer/footer'

import backgroundImg from '../images/whoMLEWHre-164843-FISH-Deck-High-resize.jpg'

const windowHeight = window.innerHeight - 150

const StyledError = styled.div`
  height: ${windowHeight + 'px'};
`

class Error extends Component {
  render() {
    let error
    if (!this.props.error && this.props.location && this.props.location.state && this.props.location.state.error) {
      error = this.props.location.state.error
    }

    return (
      <StyledError>
        <Header />
        <Container background={backgroundImg} {...this.props} containerStyle={{ height: windowHeight + 'px', paddingTop: '115px' }}>
          <ErrorPage error={error} />
        </Container>
        <Footer style={{ position: 'absolute', bottom: 0 }} />
      </StyledError>
    )
  }
}

export default Error
