import React, { Component } from 'react'
import { connect } from 'react-redux'

import Container from './Container'
import MilesToPoints from './Miles'
import { login } from '../actions'

const captionMargin = '24px 0px 24px 0px;'

class MilesContainer extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <Container captionMargin={captionMargin} {...this.props}>
        <MilesToPoints
          authCode={this.props.authCode}
          profile={this.props.profile}
          marriott={this.props.marriott}
          convertMiles={this.props.convertMiles}
          transferMiles={this.props.transferMiles}
        />
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authCode: state.Store.login,
    profile: state.Store.profile,
    marriott: state.Store.marriott,
    convertMiles: state.Store.convertMiles,
    transferMiles: state.Store.transferMiles,
    ...state,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: queryParameters => dispatch(login(queryParameters)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MilesContainer)
