import React, { Component } from 'react'

import Faqs from '../components/faqs/faqs'

class FrequentlyAskedQuestions extends Component {
  render() {
    return <Faqs i18n={this.props.i18n} />
  }
}

export default FrequentlyAskedQuestions
