import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { translate } from 'react-i18next'

import { transferMiles } from '../../../../actions'

import Button from '../../../common/button'

class ConfirmForm extends Component {
  renderForm() {
    const t = this.props.t
    const { handleSubmit, submitting } = this.props

    return (
      <form name="confirmForm" onSubmit={handleSubmit}>
        <Button type="submit" disabled={submitting} alt="Confirm">
          {t('miles.right.confirm.form.button')}
        </Button>
      </form>
    )
  }

  render() {
    return this.renderForm()
  }
}

// Decorate the form component with redux-form
const ConfirmReduxForm = reduxForm({
  form: 'confirmForm', // a unique name for this form
})(ConfirmForm)

// Decorate the form component with connect
const mapStateToProps = (state, ownProps) => {
  return {
    //...state,
  } // when in doubt pass nothing
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (formData, dispatch, props) => {
      dispatch(props.startSubmit)
      dispatch(transferMiles(props.authCode, props.marriott, props.convertMiles, props.kountSessionID))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translation')(ConfirmReduxForm))
