import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'
import styled from 'styled-components'

import ConfirmForm from './forms/confirmForm'

import FormContainer from '../../common/formContainer'
import Subtitle from '../../common/formSubTitle'
import Title from '../../common/formTitle'
import StyledText from '../../common/formText'

const StyledSpan = styled.span`
  font-weight: 600;
`

const StyledHr = styled.hr`
  background: #002244;
  width: 77%;
  height: 1px;
  border: none;
  margin: 15px auto;
`

class RightColumnConfirm extends Component {
  render() {
    const t = this.props.t

    const authCode = this.props.authCode
    const marriott = this.props.marriott
    const convertMiles = this.props.convertMiles

    const firstName = this.props.profile.firstname
    const balance = this.props.profile.balance
    const memberLevel = this.props.profile.memberLevel
    const kountSessionID = this.props.profile.kountSessionID
    const eliteLevels = this.props.elite
    const miles = this.props.convertMiles.convertMiles
    const maskedMP = this.props.maskedMP
    const maskedMR = this.props.maskedMR

    return (
      <FormContainer id="RightColumn">
        <div>
          <Title>
            {t('miles.right.marriott.title')} {firstName}
          </Title>
          <Subtitle>
            <StyledSpan>
              <Trans i18nKey={eliteLevels[memberLevel]}>
                Premier
                <sup>®</sup> 1K
                <sup>®</sup>
              </Trans>
            </StyledSpan>
            <br />
            <StyledSpan>
              <Trans i18nKey="miles.right.marriott.mp_number_title">
                MileagePlus
                <sup>®</sup> number:
              </Trans>
            </StyledSpan>
            &nbsp;
            {maskedMP}
            <br />
            <StyledSpan>{t('miles.right.marriott.balance')}</StyledSpan> {balance}
          </Subtitle>
          <StyledText>
            <Trans i18nKey="miles.right.convert.textA">
              Congratulations — as a MileagePlus Premier
              <sup>®</sup> member you're eligible to convert your award miles into Marriott Rewards points.
            </Trans>
          </StyledText>
          <Subtitle>
            <StyledSpan>{t('miles.right.convert.mr_number_title')}</StyledSpan> {maskedMR}
          </Subtitle>
          <StyledHr />
          <StyledText>{t('miles.right.confirm.textA')}</StyledText>
          <StyledText>
            {t('miles.right.confirm.textB.pre')}
            {miles} &nbsp;
            {t('miles.right.confirm.textB.miles')} &nbsp;
            {miles} &nbsp;
            {t('miles.right.confirm.textB.points')} &nbsp;
          </StyledText>

          {/* FORM */}
          <ConfirmForm authCode={authCode} marriott={marriott} convertMiles={convertMiles} kountSessionID={kountSessionID} />
        </div>
      </FormContainer>
    )
  }
}

export default translate('translation')(RightColumnConfirm)
