import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { translate } from 'react-i18next'
import withTracker from '../analytics/withTracker'
import ReactGA from 'react-ga4'

import Header from '../components/header/header'
import Banner from './Banner'
import SignupWithoutTracker from './SignupContainer'
import PointsWithoutTracker from './Points'
import MarriottLogin from '../components/marriottLogin/marriottLogin'
import MilesWithoutTracker from './MilesContainer'
import Promotion from '../components/promotion/promotion'
import PromotionTwo from '../components/promotion/promotionTwo'
import SocialMediaLinks from '../components/socialMediaLinks/socialMediaLinks'
//import UnitedBookingWidget from '../components/unitedBookingWidget/unitedBookingWidget'
import EnrollLink from '../components/enrollLink/enrollLink'
import Footer from '../components/footer/footer'

import signInImg from '../images/signIn.jpg'
import rewardsImg from '../images/cabin.jpg'
import rewardsImgCropped from '../images/cabin_cropped.jpg'
import marriottLoginImg from '../images/marriottLogin.jpg'
import milesImg from '../images/miles.jpg'
import milesImgCropped from '../images/miles_cropped.jpg'

import i18next from 'i18next'

const Signup = withTracker(SignupWithoutTracker)
const Points = withTracker(PointsWithoutTracker)
const Miles = withTracker(MilesWithoutTracker)

const StyledHome = styled.div`
  min-width: 320px;
`

const Image = styled.img`
  height: 400px;
  width: 100%;
  object-fit: cover;
`

const ResponsiveSwitchImage = styled.div`
  height: 400px;
  width: 100%;
  object-fit: cover;
  background-image: url(${props => props.smallImgUrl});
  background-size: cover;
  background-position-x: right;
  background-position-y: top;
  display: ${props => (/^en/.test(i18next.language) && props.hideOnEnglish ? 'none' : 'block')};

  @media (min-width: ${props => props.theme.minWidth.giga}) {
    background-image: url(${props => props.bigImgUrl});
  }
`

class Home extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      hash: '#signup',
      lastHash: '',
      sectionChange: false,
      scrollHandlerTimeoutId: 0,
    }

    this.refMap = new Map()
    this.refMap.set('#signup', React.createRef())
    this.refMap.set('#points', React.createRef())
    this.refMap.set('#rewards', React.createRef())
    this.refMap.set('#marriottLogin', React.createRef())
    this.refMap.set('#miles', React.createRef())
  }

  static contextTypes = {
    router: PropTypes.object,
  }

  handleScrollEvent = e => {
    const y = window.pageYOffset

    if (!this.state.scrollHandlerTimeoutId) {
      const newTimeoutId = window.setTimeout(this.handleScroll, 100, y)
      this.setState({ scrollHandlerTimeoutId: newTimeoutId })
    }
  }

  handleScroll = y => {
    if (y < 430) {
      this.setState({ hash: '#signup', lastHash: this.state.hash, sectionChange: true })
    } else if (y < 1150) {
      this.setState({ hash: '#points', lastHash: this.state.hash, sectionChange: true })
    } else if (y < 1950) {
      this.setState({ hash: '#rewards', lastHash: this.state.hash, sectionChange: true })
    } else if (y < 2650) {
      this.setState({ hash: '#marriottLogin', lastHash: this.state.hash, sectionChange: true })
    } else {
      this.setState({ hash: '#miles', lastHash: this.state.hash, sectionChange: true })
    }

    this.setState({ scrollHandlerTimeoutId: 0 })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollEvent)

    // translate path to fragment/section (trust next section to scroll)
    const { history } = this.context.router
    if (this.props.location && this.props.location.pathname === '/points') {
      history.push('/#points')
    }
    if (this.props.location && this.props.location.pathname === '/rewards') {
      history.push('/#rewards')
    }
    if (this.props.location && this.props.location.pathname === '/miles') {
      history.push('/#miles')
    }

    // scroll to section defined in url fragment
    // probably don't need to compare against stateHash because it should always be '#signup' (default) on mount
    let urlHash = this.props.location.hash
    let stateHash = this.state.hash
    if (stateHash !== urlHash) {
      this.setState({ hash: urlHash })

      const sectionRef = this.refMap.get(urlHash)
      if (sectionRef && sectionRef.current) {
        sectionRef.current.scrollIntoView()
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollEvent)
  }

  componentDidUpdate(prevProps) {
    if (this.state.lastHash !== this.state.hash && this.state.sectionChange) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + this.state.hash })
      this.setState({ sectionChange: false })
    }
  }

  render() {
    const { t } = this.props

    return (
      <StyledHome>
        <Header profile={this.props.profile.data} currentSection={this.state.hash} />
        {/* <Banner isTopBanner="true" {...this.props} /> */}
        <Signup
          containerId="signup"
          name="signup"
          containerBackground={signInImg}
          anchorRef={this.refMap.get('#signup')}
          {...this.props}
        />
        <Points containerId="points" anchorRef={this.refMap.get('#points')} {...this.props} />
        {/* <Image src={pointsImg} alt={t('points.imageCaption')} />
        <Rewards containerId="rewards" name="rewards" anchorRef={this.refMap.get('#rewards')} {...this.props} /> */}
        <ResponsiveSwitchImage bigImgUrl={rewardsImg} smallImgUrl={rewardsImgCropped} title={t('rewards.imageCaption')} />
        <MarriottLogin containerId="marriottLogin" anchorRef={this.refMap.get('#marriottLogin')} />
        <Image src={marriottLoginImg} alt={t('marriottLogin.imageCaption')} />
        <Miles containerId="miles" anchorRef={this.refMap.get('#miles')} {...this.props} />
        <PromotionTwo />
        <ResponsiveSwitchImage bigImgUrl={milesImg} smallImgUrl={milesImgCropped} title={t('')} hideOnEnglish={true} />
        <SocialMediaLinks />
        {/*<UnitedBookingWidget />*/}
        <EnrollLink />
        <Footer />
      </StyledHome>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.Store.profile,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

const withTranslations = translate('translation')(Home)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslations)
